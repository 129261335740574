import Loading from "../../../Assets/lottie-files/loader-dots.json"

export const LoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: Loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

export const usersHeaderData = [
  "#",
  "User ID",
  "Login",
  "Language",
  "experienceId",
  "Project ID",
  "Plateform",
]

export const tableData = [
  "#",
  "Collection ID",
  "Mboname",
  "WhereClause",
  "Login",
  "Ready",
  "WhereClause ID",
  "Result",
  "Data Hash",
]

export const dataCollectionOptions = [
  "Login",
  "Ready",
  "WhereClause ID",
  "Result",
  "Data Hash",
  "completewhereclause",
  "mainWhereclause",
  "mainCompletewhereclause",
]

export const tableFetchData = [
  "#",
  "Collection ID",
  "Mboname",
  "WhereClause",
  "isLookUpList",
  "noFetchLimit",
  "onlyPersist",
  "label",
  "sync",
  "isRelationShip",
]

export const fetchCollectionOptions = [
  "isLookUpList",
  "noFetchLimit",
  "onlyPersist",
  "label",
  "sync",
  "isRelationShip",
  "completewhereclause",
  "mainWhereclause",
  "mainCompletewhereclause",
]

export const tableFailedDataHeader = [
  "#",
  "Actions",
  "Collection ID",
  "Error",
  "Users",
  "Time Out",
  "Date",
  "Processing",
  "Refresh Number",
  "Stop Refetch",
]

export const tableFailedDataHeaderOptions = [
  "#",
  "Collection ID",
  "Error",
  "Users",
  "Time Out",
  "Date",
  "Processing",
  "Refresh Number",
  "Stop Refetch",
]

export const InputLabels = [
  "mboname",
  "login",
  "whereclause",
  "whereClauseId",
  "completewhereclause",
  "mainWhereclause",
  "mainCompletewhereclause",
]

export const advancedSearchCriteria = {
  0: {
    mboname: "",
    login: "",
    whereclause: "",
    whereClauseId: "",
    completewhereclause: "",
    mainWhereclause: "",
    mainCompletewhereclause: "",
  },
  1: {
    mboname: "",
    login: "",
    whereclause: "",
    whereClauseId: "",
    completewhereclause: "",
    mainWhereclause: "",
    mainCompletewhereclause: "",
  },
  2: {
    mboname: "",
    login: "",
    whereclause: "",
    whereClauseId: "",
    completewhereclause: "",
    mainWhereclause: "",
    mainCompletewhereclause: "",
  },
}

export const advancedInputState = {
  mboname: true,
  login: true,
  whereclause: true,
  whereClauseId: true,
  completewhereclause: true,
  mainWhereclause: true,
  mainCompletewhereclause: true,
}

export const toggleFilter = {
  0: { toggleFilter: false },
  1: { toggleFilter: false },
  2: { toggleFilter: false },
}

export const mockdata = [
  {
    collectionId: "8f48c355cd88e7d26d9f9413fc671e69",
    completewhereclause: "enabled = 1",
    dataHash: 1715938779528,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "LANGUAGE",
    ready: true,
    refreshUser: [],
    result: 2,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.31807347900318717",
    whereclause: "enabled = 1",
  },
  {
    collectionId: "38c4f6d343753db50c786cb40d5a7589",
    completewhereclause: "domainid = 'ZZSEX'",
    dataHash: 1715938779534,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "ALNDOMAIN",
    ready: true,
    refreshUser: [],
    result: 0,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.2972395334719413",
    whereclause: "domainid = 'ZZSEX'",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
  {
    collectionId: "8a84f215f146b5132742d6b3382c306f",
    completewhereclause: "1 = 1",
    dataHash: 1715938779548,
    login: ["maxapps_mehdi"],
    mainCompletewhereclause: "",
    mainWhereclause: "",
    mboname: "SITE",
    ready: true,
    refreshUser: [],
    result: 27,
    whereClauseId: "imxComponent_whereclause_1715263025869_0.02400254825592074",
    whereclause: "1 = 1",
  },
]
