import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Switch from "react-switch";
import { useTheme } from "../../theme/themeContext";
import Icon from "../CustomIcon/Icon";
import Editor from "../../views/Designer/components/XmlSide/AceEditor";
import { useSelector } from "react-redux";
import "./index.scss";
import {
  deleteConditionByRef,
  deleteItemByIndex,
  duplicateItemByIndex,
  editByIndex,
} from "./services";
import Dropdown from "../Dropdown";
import { AppContext } from "../../views/Designer/services/AppContext";

const Property = ({
  Key = "",
  Value = "",
  data = [],
  setData,
  index,
  disabled = false,
  disableKey = false,
  readOnly = true,
  condition = false,
  lookup = false,
  scripts = false,
  actions = true,
  inputType = "text",
  minHeightExp = "2.5rem",
  mode = "javascript",
  backgroundColor = "transparent",
  existRef = false,
  options = [],
  XMLChanged = false,
  emptyIndex = false,
}) => {
  const [value, setValue] = useState(Value);
  const [key, setKey] = useState(Key);
  const { themeMode, themeColors } = useTheme();
  const { emptyKey, emptyValue, setEmptyValue, setEmptyKey } =
    useContext(AppContext);
  let codeEditor = useSelector((state) =>
    state?.entities?.codeeditor ? state?.entities?.codeeditor : {}
  );
  const inputRef = useRef(null);
  const inputValRef = useRef(null);
  let { fontSize, theme } = codeEditor;

  const selectOptions = useMemo(
    () => [
      { label: "Select an option", value: "", disabled: true },
      ...options,
    ],
    [options]
  );

  useEffect(() => {
    setKey(Key?.replace(/\s/g, ""));
    setValue(Value);
  }, [Key, Value]);

  const handleKeyChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setKey(newValue);
      editByIndex(data, setData, index, "key", newValue);
    },
    [data, setData, index]
  );

  const handleValueChange = (event) => {
    if (condition) {
      return;
    }
    setValue(event.target.value);
    editByIndex(data, setData, index, "value", event.target.value);
  };

  const handleSwitch = () => {};

  const DropdownOptions = [
    {
      label: "Copy Property",
      icon: "copy",
      // action: () => copyProp(data2set),
      active: true,
    },
    {
      label: "Duplicate Property",
      icon: "duplicate-prop",
      action: () => duplicateItemByIndex(data, setData, index),
      active: true,
    },
    {
      label: "Delete Property",
      icon: "del",
      action: () => deleteItemByIndex(data, setData, index),
      active: true,
    },
  ];

  useEffect(() => {
    if (index === data.length - 1 && !readOnly && (key === "" || key == null)) {
      inputRef.current.focus();
    }

    if (inputRef.current && emptyValue) {
      inputRef.current.blur();
    }
    if (inputValRef && emptyKey) {
      inputValRef.current.blur();
    }
  }, [emptyValue, emptyKey]);
  return (
    <>
      {condition || lookup || scripts ? (
        <>
          {condition && (
            <div className="conditionProperty">
              <div
                className={`Condition-container ${!existRef && "UnexistRef"}`}
                style={{
                  backgroundColor: backgroundColor,
                  width: readOnly && "100%",
                }}
              >
                <div className="conditionRef">{value}</div>

                <div className="conditionActions">
                  {!existRef ? (
                    <div className="unexistRefMsg">Not exist</div>
                  ) : (
                    <Switch
                      onChange={handleSwitch}
                      checked={false}
                      height={16}
                      width={34}
                    />
                  )}
                </div>
              </div>
              {!readOnly && (
                <div
                  className={"deleteCondition"}
                  onClick={() => deleteConditionByRef(data, setData, value)}
                >
                  <div
                    className={`dltCondition ${!existRef && "UnexistRefDel"}`}
                  >
                    <Icon
                      icon="times"
                      size={8}
                      color={!existRef ? "green" : "black"}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {lookup && (
            <div className="Input-container">
              <div className="utilityValue">{value}</div>
              <div className="UtilityLabel">{lookup ? "Lookup" : key}</div>
            </div>
          )}
          {scripts && (
            <React.Fragment>
              <div className="Input-container">{value}</div>
              <select
                className="Input-container selectOption"
                value={key}
                onChange={disabled ? null : (e) => handleKeyChange(e)}
                style={{ justifyContent: "flex-end" }}
              >
                {selectOptions.map((option, index) => (
                  <option
                    key={index}
                    value={option.value}
                    disabled={option?.disabled || false}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </React.Fragment>
          )}
        </>
      ) : (
        <React.Fragment>
          <input
            ref={inputRef}
            placeholder="Property"
            className="input2"
            value={key}
            onChange={disabled || disableKey ? null : (e) => handleKeyChange(e)}
            readOnly={XMLChanged}
            style={{
              borderColor: emptyKey && emptyIndex ? "#DC2626" : "#ccc",
              outline: "none",
              pointerEvents: readOnly ? "none" : "auto",
            }}
            onFocus={(event) => {
              if (emptyValue) {
                event.target.blur();
                setEmptyValue(false);
              } else if (!XMLChanged && !readOnly) {
                event.target.style.borderColor =
                  themeColors[themeMode].SupportButtonBg;
              }
            }}
            onBlur={(event) => {
              event.target.style.borderColor = "#ccc";
            }}
          />
          {inputType?.toLowerCase() === "expression" ? (
            <Editor
              theme={theme}
              fontSize={fontSize}
              value={value}
              readOnly={readOnly}
              property={true}
              placeholder={"Write expression"}
              minHeight={minHeightExp}
              language={mode}
            />
          ) : (
            <textarea
              ref={inputValRef}
              className="input2"
              value={value}
              onChange={disabled ? null : (e) => handleValueChange(e)}
              readOnly={XMLChanged}
              style={{
                borderColor: emptyValue && emptyIndex ? "#DC2626" : "#ccc",
                outline: "none",
                minHeight: "2.5rem",
                height: "2.5rem",
                maxHeight: "",
                fontSize: "14px",
                resize: "vertical",
                padding: "0.5rem 0.5rem",
                pointerEvents: readOnly ? "none" : "auto",
                overflow: "hidden",
              }}
              onFocus={(event) => {
                if (emptyKey) {
                  event.target.blur();
                  setEmptyKey(false);
                } else if (!XMLChanged && !readOnly) {
                  event.target.style.borderColor =
                    themeColors[themeMode].SupportButtonBg;
                }
              }}
              onBlur={(event) => {
                event.target.style.borderColor = "#ccc";
              }}
            />
          )}
        </React.Fragment>
      )}
      {!readOnly && !XMLChanged && !condition && actions && (
        <div className="buttons">
          {lookup || scripts ? (
            <div
              className="deleteBtn"
              style={{ justifyContent: scripts && "center" }}
              onClick={
                !scripts
                  ? () => setData({})
                  : () => deleteItemByIndex(data, setData, index)
              }
            >
              <Icon icon="delete" size={18} />
            </div>
          ) : (
            <Dropdown options={DropdownOptions} activeDropdown={!readOnly}>
              {({ isOpen }) => (
                <Icon
                  icon="More"
                  size={28}
                  color={
                    isOpen
                      ? themeColors[themeMode].primary
                      : themeColors[themeMode].text
                  }
                />
              )}
            </Dropdown>
          )}
        </div>
      )}
    </>
  );
};

export default Property;
