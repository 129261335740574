import React, { useState, useEffect } from "react";
import DataCollectionTable from "./components/DataCollectionTable/index";
import FetchCollectionTable from "./components/FetchCollectionTable/index.jsx";
import FailedCollectionTable from "./components/FailedDataCollectionTable/index.jsx";
import EnvironmentTable from "./components/EnvironmentTable/index";
import UsersTable from "./components/UsersTable/index";
import Interceptor from "../Interceptor/index.jsx";
import TableControlButtons from "./components/TableControlButtons/index.jsx";
import Debugger from "../Debugger/index.jsx";
import {
  advancedSearchCriteria,
  toggleFilter,
  advancedInputState,
  tableFetchData,
  tableData,
  dataCollectionOptions,
} from "./data.js";

import "./index.scss";
import IMXLogger from "../IMXLogger/index.jsx";

const Dashboard = () => {
  const [toggleTable, setToggleTable] = useState(0);
  const [toggleAdvancedSearch, setToggleAdvancedSearch] =
    useState(toggleFilter);
  const [advancedSearchValue, setAdvancedSearchValue] = useState(
    advancedSearchCriteria
  );
  const [advancedInputVisibility, setAdvancedInputVisibility] =
    useState(advancedInputState);
  const [selectedFailedDataId, setSelectedFailedDataId] = useState(null);
  const [scrollPositions, setScrollPositions] = useState({
    fetch: 0,
    data: 0,
    failed: 0,
  });
  const [activeRows, setActiveRows] = useState({
    fetch: null,
    data: null,
    failed: null,
  });
  const [searchValues, setSearchValues] = useState({
    fetch: "",
    data: "",
    failed: "",
  });
  const [isSearching, setIsSearching] = useState({
    fetch: false,
    data: false,
    failed: false,
  });
  const [filteredData, setFilteredData] = useState({
    fetch: [],
    data: [],
    failed: [],
  });

  const [dataToFetch, setDataToFetch] = useState("");
  const [FailedDataToFetch, setFailedDataToFetch] = useState("");

  const [fetchSelectedColumns, setFetchSelectedColumns] =
    useState(tableFetchData);
  const [dataSelectedColumns, setDataSelectedColumns] = useState(tableData);

  const [fetchToggleDropDown, setFetchToggleDropdown] = useState(false);

  const handleCheckboxChange = (
    event,
    setSelectedColumns,
    collectionOptions
  ) => {
    const columnName = event.target.value;
    setSelectedColumns((prevColumns) => {
      const newColumns = prevColumns.includes(columnName)
        ? prevColumns.filter((col) => col !== columnName)
        : [...prevColumns, columnName];
      return newColumns.sort(
        (a, b) => collectionOptions.indexOf(a) - collectionOptions.indexOf(b)
      );
    });
  };

  const handleFailedDataClick = (dataCollectionId) => {
    setSelectedFailedDataId(dataCollectionId);
    setToggleTable(2);
  };

  const handledataToFetch = (dataCollectionId) => {
    setDataToFetch(dataCollectionId);
  };

  const handleFailedDataToFetch = (failedCollectionId) => {
    const extractedPart = failedCollectionId.split("-")[0];
    setFailedDataToFetch(extractedPart);
    setToggleTable(0);
  };

  const updateScrollPosition = (table, position) => {
    setScrollPositions((prevPositions) => ({
      ...prevPositions,
      [table]: position,
    }));
  };

  const updateActiveRow = (table, row) => {
    setActiveRows((prevRows) => ({
      ...prevRows,
      [table]: row,
    }));
  };

  const updateSearchValue = (table, value) => {
    setSearchValues((prevValues) => ({
      ...prevValues,
      [table]: value,
    }));
  };

  const updateIsSearching = (table, value) => {
    setIsSearching((prevValues) => ({
      ...prevValues,
      [table]: value,
    }));
  };

  const updateFilteredData = (table, data) => {
    setFilteredData((prevData) => ({
      ...prevData,
      [table]: data,
    }));
  };

  useEffect(() => {
    if (toggleTable !== 2) {
      setSelectedFailedDataId(null);
    }
    if (toggleTable !== 0) {
      setFailedDataToFetch(null);
    }
  }, [toggleTable]);

  const tabs = [
    {
      label: "Failed Data collection",
      component: (
        <FailedCollectionTable
          toggleTable={toggleTable}
          toggleAdvancedSearch={toggleAdvancedSearch}
          setToggleAdvancedSearch={setToggleAdvancedSearch}
          advancedSearchValue={advancedSearchValue}
          setAdvancedSearchValue={setAdvancedSearchValue}
          advancedInputVisibility={advancedInputVisibility}
          setAdvancedInputVisibility={setAdvancedInputVisibility}
          selectedFailedDataId={selectedFailedDataId}
          scrollPosition={scrollPositions.failed}
          updateScrollPosition={(position) =>
            updateScrollPosition("failed", position)
          }
          activeRow={activeRows.failed}
          setActiveRow={(row) => updateActiveRow("failed", row)}
          searchValue={searchValues.failed}
          setSearchValue={(value) => updateSearchValue("failed", value)}
          isSearching={isSearching.failed}
          setIsSearching={(value) => updateIsSearching("failed", value)}
          filteredData={filteredData.failed}
          setFilteredData={(data) => updateFilteredData("failed", data)}
          handleFailedDataToFetch={handleFailedDataToFetch}
        />
      ),
    },

    {
      label: "Data collection",
      component: (
        <DataCollectionTable
          toggleTable={toggleTable}
          toggleAdvancedSearch={toggleAdvancedSearch}
          setToggleAdvancedSearch={setToggleAdvancedSearch}
          advancedSearchValue={advancedSearchValue}
          setAdvancedSearchValue={setAdvancedSearchValue}
          advancedInputVisibility={advancedInputVisibility}
          setAdvancedInputVisibility={setAdvancedInputVisibility}
          onFailedDataClick={handleFailedDataClick}
          scrollPosition={scrollPositions.data}
          updateScrollPosition={(position) =>
            updateScrollPosition("data", position)
          }
          activeRow={activeRows.data}
          setActiveRow={(row) => updateActiveRow("data", row)}
          searchValue={searchValues.data}
          setSearchValue={(value) => updateSearchValue("data", value)}
          isSearching={isSearching.data}
          setIsSearching={(value) => updateIsSearching("data", value)}
          filteredData={filteredData.data}
          setFilteredData={(data) => updateFilteredData("data", data)}
          selectedColumns={dataSelectedColumns}
          setSelectedColumns={setDataSelectedColumns}
          handleCheckboxChange={(e) =>
            handleCheckboxChange(
              e,
              setDataSelectedColumns,
              dataCollectionOptions
            )
          }
        />
      ),
    },

    {
      component: <UsersTable />,
      label: "Users",
    },
    {
      component: <Interceptor />,
      label: "Interceptor",
    },
    {
      component: <IMXLogger />,
      label: "Logger",
    },
    {
      label: "Debug",
      component: <Debugger />,
    },
  ];

  return (
    <div className="dashboard-container">
      <TableControlButtons
        toggleTable={toggleTable}
        setToggleTable={setToggleTable}
        tabs={tabs}
      />
      {tabs[toggleTable].component}
    </div>
  );
};

export default Dashboard;
