import { axiosProviderNode } from "../../../../../services/http/httpService.js"
import { services } from "./dashboardEndPoints"

async function fetchSysInfo() {
  try {
    const transactionNodeService = services.NODE_ENDPOINT_GET_SYSINFO

    const response = await axiosProviderNode.get(transactionNodeService)
    return response?.data
  } catch (error) {
    throw error
  }
}

export default fetchSysInfo
