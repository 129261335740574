import React from "react";
import "./index.scss";

function Modal({
  children,
  openModal = false,
  bg = "",
  tab = false,
  field = false,
  app = false,
  right,
}) {
  return (
    <React.Fragment>
      {openModal ? (
        <>
          {tab && !app ? (
            <div
              className="Model-mainContainer"
              style={{
                background: bg,
              }}
            >
              <div
                className="ModalContainer"
                style={{
                  background: bg,
                  top: tab ? "5%" : field ? "0%" : null,
                  right: app ? "1.5%" : null,
                }}
              >
                {children}
              </div>
            </div>
          ) : (
            <div
              className="ModalContainer"
              style={{
                background: bg,
                top: tab ? "5%" : field ? "0%" : null,
                right: app ? "1.5%" : null,
              }}
            >
              {children}
            </div>
          )}{" "}
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default Modal;
