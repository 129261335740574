import { useState, useEffect } from "react";
import loadSysInfo from "../components/Dashboard/services/loadSysInfo";

function useSysInfo() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    setLoading(true);

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        setLoading(false);
      }
    }, 15000);

    loadSysInfo((fetchedData) => {
      if (!isCancelled) {
        setData(fetchedData);
        setLoading(false);
        clearTimeout(timeoutId);
      }
    });

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, []);

  return { data, loading };
}

export default useSysInfo;
