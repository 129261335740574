import React, { useRef, useEffect, useState } from "react";
import MonacoEditor from "@monaco-editor/react";
import PropTypes from "prop-types";
import "./index.scss";

function Editor({
  theme = "vs-dark",
  fontSize = 16,
  onChange = () => {},
  readOnly = true,
  value = "",
  language = "xml",
  placeholder = "Write XML code",
  wordWrap,
  lineNumber = 1,
  columnNumber = 1,
  height = "100%",
  className = "",
  dataToSet,
}) {
  const editorRef = useRef(null);
  const [editorValue, setEditorValue] = useState(value);
  const handleEditorMount = (editor, monaco) => {
    editorRef.current = editor;

    // Existing commands
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_F, () =>
      editor.getAction("actions.find").run()
    );

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_B, () =>
      editor.getAction("editor.action.formatDocument").run()
    );

    // Toggle Line Comment
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.SLASH, () =>
      editor.getAction("editor.action.commentLine").run()
    );
  };

  useEffect(() => {
    if (editorRef.current) {
      const editor = editorRef.current;
      const model = editor.getModel();
      if (model) {
        const lineCount = model.getLineCount();
        if (lineNumber <= lineCount) {
          const position = { lineNumber, column: columnNumber };
          editor.setPosition(position);
          editor.revealPositionInCenter(position);
        }
      }
    }
  }, [lineNumber, columnNumber, dataToSet]);

  console.log("rrrrrrrrrrrrr", value);
  return (
    <MonacoEditor
      className={className}
      height={height}
      width="100%"
      defaultValue={placeholder}
      language={language}
      theme={theme}
      value={value}
      options={{
        readOnly,
        minimap: { enabled: true },
        fontSize,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        tabSize: 2,
        lineNumbers: "on",
        lineNumbersMinChars: 5,
        wordWrap: wordWrap ? "on" : "off",
        wrappingIndent: "indent",
        bracketPairColorization: { enabled: true },
        linkDetection: true,
        autoClosingBrackets: "always",
        autoClosingQuotes: "always",
        cursorBlinking: "blink",
        showFoldingControls: "mouseover",
        highlightActiveIndentGuide: true,
        renderWhitespace: "selection",
        // find: {
        //   autoFindInSelection: "always",
        // }, */
      }}
      onMount={handleEditorMount}
      onChange={(newValue, event) => {
        onChange(newValue);
        // onChange(newValue, event);
      }}
    />
  );
}

Editor.defaultProps = {
  theme: "vs-dark",
  fontSize: 16,
  onChange: () => {},
  readOnly: true,
  value: "",
  language: "xml",
  placeholder: "Write XML code",
  lineNumber: 1,
  columnNumber: 1,
  height: "100%",
  className: "",
  wordWrap: true,
};

Editor.propTypes = {
  theme: PropTypes.string,
  fontSize: PropTypes.number,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  language: PropTypes.string,
  placeholder: PropTypes.string,
  lineNumber: PropTypes.number,
  columnNumber: PropTypes.number,
  height: PropTypes.string,
  className: PropTypes.string,
  wordWrap: PropTypes.bool,
};

export default Editor;
