import { useState } from "react";
import "./index.scss";
import UserStat from "./components/UserStat/index";
import PerformanceItem from "./components/PerformanceItem/index.jsx";
import CustomButton from "../../../../components/CustomButton";
import ThresholdForm from "./components/ThresholdForm/index.jsx";
import useButtonTheme from "../../customHooks/useButtonTheme";
import { hardwareArray, userArray } from "./data.js";
import { useMonitorSocket } from "../../customHooks/useMonitorSocket.js";
import Icon from "../../../../components/CustomIcon/Icon.jsx";

function Sidebar() {
  const [showModal, setShowModal] = useState(false);
  const sidebarButton = useButtonTheme("sidebarButton");
  const data = useMonitorSocket();
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const performanceData =
    data?.dashboard?.hardware.length > 0
      ? data?.dashboard?.hardware
      : hardwareArray;
  const usersData =
    data?.dashboard?.users.length > 0 ? data?.dashboard?.users : userArray;

  const toggleSidebarFn = () => {
    setToggleSidebar((prev) => !prev);
  };

  const handleContainerClick = (e) => {
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const clickX = e.clientX - rect.left;

    const padding = parseFloat(getComputedStyle(container).paddingRight);
    if (clickX >= rect.width - padding) {
      toggleSidebarFn();
    }
  };

  return (
    <div className="sidebar-Monitor-container" onClick={handleContainerClick}>
      {toggleSidebar && (
        <div className="monitor-sideBar">
          <div className="sidebar-users">
            <p className="users-title">Users</p>
            {usersData?.map((user, index) => (
              <UserStat
                key={index}
                number={user.number}
                description={user.type}
              />
            ))}
          </div>
          <div className="sidebar-performance">
            <p className="performance-title">Performance</p>
            {performanceData?.map((item, index) => (
              <PerformanceItem
                key={index}
                label={item?.name}
                value={
                  item.name === "RAM"
                    ? { main: item?.current, unit: `/${item?.max} GB` }
                    : `${item?.current}%`
                }
              />
            ))}
          </div>
          <CustomButton
            isActive={showModal}
            {...sidebarButton}
            onClick={() => setShowModal(!showModal)}
          />
          {showModal && <ThresholdForm setShowModal={setShowModal} />}
        </div>
      )}
      <button
        className="toggle-sidebar"
        onClick={(e) => {
          e.stopPropagation();
          toggleSidebarFn();
        }}
      >
        <Icon
          icon="toggle-sidebar"
          size={22}
          className={toggleSidebar ? "icon-rotated" : ""}
          style={{
            color: toggleSidebar ? "#989898" : "#4C36A5",
          }}
        />
      </button>
    </div>
  );
}
export default Sidebar;
