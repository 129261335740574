import React, { useContext, useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { navigateTo } from "../../services/navigationMiddleware";
import Attribute from "../Attribute";
import imxtools from "imxtools";
import { handleComponentProp } from "../../services/handleComponentProperties";
import "./index.scss";
import HeaderBody from "./Header";
import FooterBody from "./Footer";
import previewImg from "./../../../../../../../assets/maxapps-assets/previewImg.png";
import DragItemAttribute from "../../../../ComponentsLibrary/DragItemAttribute";
import { AppContext } from "../../../../../services/AppContext";
import DropTargetBody from "../DropTargetBody";
import { isSelectedComponent } from "../../services/isSelectedComponent";

const Body = ({
  parentData = {},
  profile = false,
  data = {},
  listData = {},
  previewImage = false,
  handleOpenActions = () => {},
  path = [],
  readOnly = true,
  relationship = false,
  setOpenRelationship,
  currentPath = [],
  index = 0,
  selectedBody = 0,
  setSelectedBody = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoverBody, setHoverBody] = useState(false);
  const [hoverAtt, setHoverAtt] = useState(false);
  const [clickHoldTimer, setClickHoldTimer] = useState(null);
  const [dataAttributes, setDataAttributes] = useState(
    imxtools?.toArray(data?.attribute)
  );
  let store = useStore();
  const { setIsDragingIn, setOpenInspection } = useContext(AppContext);
  const { setBodyHeader, bodyHeader } = useContext(AppContext);

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  function handleNavigateTo(e) {
    e.stopPropagation();
    if (listData?.detail) {
      handleComponentProp(e, null, listData, path, store, "detail");
      navigateTo(store, "Detail", {
        data: listData,
        path: [...path, "detail"],
      });
    } else {
      handleComponentProp(e, null, listData, path, store, "list");
      navigateTo(store, "Detail", {
        data: listData,
        path: [...path, "detail"],
      });
    }
  }

  const [value, setValue] = useState("");
  const lang = useSelector((state) => state?.entities?.deviceview?.language);
  const handleChange = (evt) => {
    evt.preventDefault();
    setValue(evt.currentTarget.value);
  };

  const handleMouseDown = (event) => {
    if (!readOnly) {
      return;
    }
    event.stopPropagation();
    setClickHoldTimer(
      setTimeout(() => {
        handleOpenActions(event, true);
      }, 2000)
    );
  };

  const handleMouseUp = (event) => {
    if (!readOnly) {
      return;
    }
    event.stopPropagation();
    clearTimeout(clickHoldTimer);
  };
  const handleInspection = () => {
    setIsOpen(true);
    setOpenInspection(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    clearTimeout(clickHoldTimer);
  };
  useEffect(() => {
    setDataAttributes(imxtools?.toArray(data?.attribute));
  }, [data?.attribute]);
  
  return (
    <React.Fragment>
      <DropTargetBody
        accept={["attribute", "header", "footer", "thumb"]}
        dragParent={[...path, "body"]}
        className={`body-container ${!readOnly && !hoverAtt && "hovered"} ${
          data?.display?.toLowerCase() === "cards" && "body-container-card"
        } ${
          isSelectedComponent(readOnly, [...path, "body"], currentPath) &&
          index === selectedBody
            ? "selectedComponent"
            : !readOnly && data?.display
            ? ""
            : "unSelectedComponent"
        }`}
      >
        <div
          className="drop-body-zone"
          onDoubleClick={(e) => {
            if (listData?.inspection) {
              handleInspection(listData.inspection);
            } else if (relationship) {
              setOpenRelationship(true);
              handleComponentProp(e, null, {}, path, store, "detail");
            } else {
              handleNavigateTo(e);
            }
          }}
          onClick={(e) => {
            handleComponentProp(e, null, data, path, store, "body");
            setSelectedBody(index);
          }}
          onMouseEnter={() => setHoverBody(true)}
          onMouseLeave={() => setHoverBody(false)}
          onMouseDown={(e) => handleMouseDown(e)}
          onMouseUp={(e) => handleMouseUp(e)}
        >
          {data?.thumb && (
            <div
              className={`body-thumb ${
                isSelectedComponent(
                  readOnly,
                  [...path, "body", "thumb"],
                  currentPath
                ) && "selectedComponent"
              } ${data?.display?.toLowerCase() === "cards" && "thumb-cards"}`}
              style={
                data?.thumb?.style &&
                data?.thumb?.style.includes("backgroundColor:")
                  ? {
                      backgroundColor:
                        data.thumb.style.split("backgroundColor:")[1],
                    }
                  : readOnly
                  ? { backgroundColor: "#fff" }
                  : { backgroundColor: "#0c8ce930" }
              }
              onClick={(e) => {
                handleComponentProp(
                  e,
                  null,
                  data?.thumb,
                  [...path, "body"],
                  store,
                  "thumb"
                );
              }}
            ></div>
          )}
          <div
            className={`body-att`}
            style={{ width: !data?.thumb && "100%" }}
            onClick={() => setBodyHeader(false)}
          >
            {!data?.attribute && !data?.footer && !data?.header && (
              <div className="empty-body" dragParent={path}>
                Body
              </div>
            )}
            {(data?.header || (parentData?.swipe && !readOnly)) && (
              <div className="header-body" style={{
                flexDirection: language?.toLowerCase() === "ar" && "row-reverse",
              }}>
                {data?.header && (
                  <div
                    className="body-header"
                    style={{
                      width:
                        (!parentData?.swipe ||
                          (parentData?.swipe && readOnly)) &&
                        "100%",
                    }}
                  >
                    <HeaderBody
                      data={data?.header}
                      path={[...path, "body", "header"]}
                      parentPath={[...path, "body"]}
                      readOnly={readOnly}
                      currentPath={currentPath}
                    />
                  </div>
                )}
                {!readOnly && parentData?.swipe && (
                  <div className="swipe-btn" >
                    <div
                      className={`open-swipe-icon ${
                        !readOnly && "hovered-display-point"
                      }`}
                      
                      onClick={(event) => handleOpenActions(event, true)}
                    />
                  </div>
                )}
              </div>
            )}
            {previewImage && (
              <div className="body-preview-image">
                <img
                  src={previewImg}
                  alt="imageInBody"
                  width="100%"
                  height="100%"
                />
              </div>
            )}{" "}
            {data?.attribute &&
              dataAttributes?.map((att, index) => {
                return (
                  <DropTargetBody
                    accept={"attribute"}
                    dragParent={path}
                    className="drop-attributes-zone"
                  >
                    <React.Fragment key={index}>
                      <DragItemAttribute
                        index={index}
                        component={"attribute"}
                        dragParentPath={[...path, "body"]}
                        dragData={att}
                        dataAttributes={dataAttributes}
                        setDataAttributes={setDataAttributes}
                        setIsDraging={setIsDragingIn}
                      >
                        <React.Fragment key={index}>
                          <Attribute
                            profile={profile}
                            data={att}
                            readOnly={readOnly}
                            index={
                              dataAttributes?.length === 1
                                ? null
                                : index.toString()
                            }
                            path={[...path, "body"]}
                            numAttributes={dataAttributes?.length || 0}
                            relationship={relationship}
                            currentPath={currentPath}
                            setHoverAtt={setHoverAtt}
                          />
                        </React.Fragment>
                      </DragItemAttribute>
                    </React.Fragment>
                  </DropTargetBody>
                );
              })}
            {data?.footer && (
              <FooterBody
                data={data?.footer}
                path={[...path, "body", "footer"]}
                parentPath={[...path, "body"]}
                readOnly={readOnly}
                currentPath={currentPath}
              />
            )}
          </div>
        </div>
      </DropTargetBody>
    </React.Fragment>
  );
};

export default Body;
