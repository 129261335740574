import React, { useEffect, useRef, useState } from "react";
import { useStore, useSelector } from "react-redux";
import useToasts from "../../../../components/CustomToast/useToasts";
import { useTheme } from "../../../../theme/themeContext";
import CustomButton from "../../../../components/CustomButton";
import Dropdown from "../../../../components/Dropdown";
import { handleSave, handleAddProperty } from "../../services/helpers";
import DeleteButton from "./components/DeleteButton";
import XmlUtilities from "./utility";
import NoItem from "../../../../components/NoItem";
import DropUtility from "./utility/services/DropUtility";
import { useCopyPaste } from "../../services/CopyPasteContext";
import "./index.scss";
import Icon from "../../../../components/CustomIcon/Icon";
import PropertiesList from "./PropertiesList";
import DeleteModal from "./components/DeleteModal";
import {
  handleUtilityState,
  handlePasteComponent,
  getObjectValues,
} from "./service";
import Modal from "../../../../components/CustomModal";
import { useSaveProps } from "../../services/savePropsContext";
import handleClickedItem from "../../services/helpers/handleClickedItem";
import NotAllowed from "../ComponentsLibrary/Assets/NotAllowed.svg";
import Pointer from "../ComponentsLibrary/Assets/Pointer.svg";
import { transformXml } from "../../services/transformXml";

const Properties = ({ XMLChanged = false, xmlValue }) => {
  const [openModal, setOpenModal] = useState(false);
  const [utilityState, setUtilityState] = useState("");
  const [data2set, setData2set] = useState({});
  const [showSwitchPopup, setShowSwitchPopup] = useState(false);

  const { themeMode, themeColors } = useTheme();
  const { addToast } = useToasts();
  const {
    copyProp,
    pasteProp,
    copiedProp,
    copiedCompType,
    pasteComponent,
    copyComponent,
    copiedComponent,
  } = useCopyPaste();

  const {
    simpleProps,
    conditions,
    lookup,
    scripts,
    setSimpleProps,
    setConditions,
    setLookup,
    setScripts,
  } = useSaveProps();
  const store = useStore();
  const [editorDisabled, setEditorDisabled] = useState(false);

  const { edit, skeleton, readOnly, revisionSelected } = useSelector(
    (state) => ({
      edit: state?.entities?.editor || {},
      skeleton: state?.entities?.explorer?.skeleton || {},
      readOnly: state?.entities?.explorer?.readOnly || false,
      revisionSelected: state?.entities?.revision?.revisionSelected || {},
    })
  );
  const xmlEdited = JSON.parse(localStorage.getItem("xmlEdited"));
  const {
    dataToSet: data,
    parentToEdit: parent,
    component: componentEdited,
  } = edit;

  const DropdownOptions = [
    {
      label: "Copy all Properties",
      icon: "copy",
      action: () => copyProp(data2set),
      active: !readOnly,
    },
    {
      label: "Copy Selection",
      icon: "copy",
      action: () => {},
      active: false,
    },
    {
      label: "Paste Property(s)",
      icon: "paste",
      action: () => pasteProp(data2set, setData2set),
      active: !readOnly && Object.keys(copiedProp)?.length > 0,
    },
  ];
  useEffect(() => {
    const onKeyDown = (e) => {
      try {
        if (e.ctrlKey && e.key === "s") {
          e.preventDefault();

          if (!readOnly) {
            handleSave(
              data2set,
              store,
              parent,
              getObjectValues(data),
              addToast,
              readOnly,
              [...simpleProps, ...scripts, lookup, conditions]
            );
          }
        }
      } catch (error) {
        console.error(`Error in handleKeyDown for save: ${error}`);
      }
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, data2set, parent, simpleProps, scripts, lookup, conditions]);

  useEffect(() => {
    const _filteredData = Object.keys(data ?? {})
      ?.filter(
        (att) =>
          att?.toString() !== "id" &&
          att?.toLowerCase() !== "itemorder" &&
          typeof data?.[att] !== "object"
      )
      ?.reduce((filteredObject, key) => {
        filteredObject[key] = data?.[key];
        return filteredObject;
      }, {});

    setData2set(_filteredData);
  }, [data]);
  const { res_equal } = useSaveProps();

  const spanRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);
  useEffect(() => {
    setEditorDisabled(!res_equal);
  }, [res_equal]);
  const cursorStyle = XMLChanged ? { cursor: "not-allowed" } : {};
  const handleClick = () => {
    // compareXmlValues();

    if (!readOnly && !editorDisabled && XMLChanged) {
      setShowSwitchPopup(true);
    }
  };

  return (
    <React.Fragment>
      <div className="prop-container">
        <div
          style={cursorStyle}
          onClick={handleClick}
          className={`PropertiesContainer ${
            utilityState === "maximize" && "PropertiesContainer-max"
          }`}
        >
          <div className="header">
            <div
              className="titleContainer"
              style={{
                color:
                  !readOnly && !editorDisabled && XMLChanged
                    ? "#b9b8bd"
                    : "var(--TitleContainer)",
                gap: "5px",
              }}
            >
              Properties
              {!readOnly && !editorDisabled && XMLChanged && (
                <Icon icon={"lock"} color={"#b9b8bd"} size={18} />
              )}
            </div>
          </div>
          {utilityState !== "maximize" && (
            <div className="total-container">
              <div className="propertiesBody">
                {!data || !componentEdited || parent?.length <= 1 ? (
                  <NoItem
                    icon="Maintenance"
                    text="Select a component to see the properities."
                    iconColor={themeColors[themeMode].iconLabelDisable}
                    textColor={themeColors[themeMode].iconLabelDisable}
                  />
                ) : (
                  <React.Fragment>
                    {data2set !== undefined && (
                      <>
                        <div className="itemTag">
                          <div className="SelectedItem">
                            <Icon icon="tag-prop" size={24} />
                            <div className="tag-prop-label">
                              <span className="SelectedItem-label">Tag:</span>
                              <span
                                className="SelectedItem-value"
                                style={{
                                  maxWidth: "10ch",
                                  display: "inline-block",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {componentEdited}
                              </span>
                            </div>
                          </div>
                          <div className="actions">
                            <div className="DeleteBtn">
                              <DeleteButton
                                withLabel={false}
                                tooltip="Delete component"
                                XMLChanged={XMLChanged}
                              />
                            </div>
                            <div className="separator" />
                            <div className="copyPaste">
                              <CustomButton
                                withLabel={false}
                                iconName="paste"
                                tooltip="Paste component"
                                iconSize={25}
                                iconColor={
                                  !readOnly &&
                                  Object?.keys(copiedComponent)?.length > 0
                                    ? themeColors[themeMode].text
                                    : themeColors[themeMode].textDisabled
                                }
                                onClick={() =>
                                  !readOnly &&
                                  Object?.keys(copiedComponent)?.length > 0 &&
                                  handlePasteComponent(
                                    componentEdited,
                                    parent,
                                    copiedCompType,
                                    pasteComponent
                                  )
                                }
                              />
                              <div className="separator" />

                              <CustomButton
                                withLabel={false}
                                iconName="copy"
                                tooltip="Copy component"
                                iconSize={23}
                                iconColor={
                                  !readOnly && componentEdited && !XMLChanged
                                    ? themeColors[themeMode].text
                                    : themeColors[themeMode].textDisabled
                                }
                                onClick={() =>
                                  !readOnly &&
                                  !XMLChanged &&
                                  copyComponent(data, componentEdited)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="HeaderProperties"
                          style={{
                            gridTemplateColumns: readOnly
                              ? "150px calc(100% - 150px)"
                              : "150px calc(100% - 150px) ",
                            color:
                              simpleProps?.length > 0 ||
                              scripts?.length > 0 ||
                              Object.keys(conditions)?.length > 0 ||
                              Object.keys(lookup)?.length > 0
                                ? themeColors[themeMode].text
                                : themeColors[themeMode].textDisabled,
                          }}
                        >
                          <div>Property</div>
                          <div className="RightBtns">
                            <div>Value</div>
                            <div className="PropBtns">
                              <CustomButton
                                iconName="add-circle"
                                iconSize={21}
                                iconColor={
                                  readOnly || XMLChanged
                                    ? themeColors[themeMode].textDisabled
                                    : themeColors[themeMode].text
                                }
                                color={
                                  readOnly || XMLChanged
                                    ? themeColors[themeMode].textDisabled
                                    : themeColors[themeMode].text
                                }
                                label="Add Property"
                                gap={0.5}
                                withLabel={true}
                                reverseBtn={true}
                                backgroundColor={"transparent"}
                                onClick={() =>
                                  !readOnly &&
                                  !XMLChanged &&
                                  handleAddProperty(
                                    simpleProps,
                                    setSimpleProps,
                                    addToast
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        {utilityState !== "maximize" && (
          <div
            className={`properties ${utilityState}-properties`}
            style={cursorStyle}
            onClick={handleClick}
          >
            {componentEdited && parent?.length > 1 && (
              <DropUtility
                conditions={conditions}
                setConditions={setConditions}
                setLookup={setLookup}
                scripts={scripts}
                setScripts={setScripts}
                component={componentEdited}
              >
                {simpleProps?.length >= 0 ||
                scripts?.length >= 0 ||
                Object.keys(conditions)?.length >= 0 ||
                Object.keys(lookup)?.length >= 0 ? (
                  <PropertiesList
                    readOnly={readOnly}
                    component={componentEdited}
                    skeleton={skeleton}
                    data={data2set}
                    simpleProps={simpleProps}
                    conditions={conditions}
                    lookup={lookup}
                    scripts={scripts}
                    setSimpleProps={setSimpleProps}
                    setConditions={setConditions}
                    setLookup={setLookup}
                    setScripts={setScripts}
                    XMLChanged={XMLChanged}
                  />
                ) : (
                  <NoItem
                    icon="Maintenance"
                    text="No Property to display"
                    iconColor={themeColors[themeMode].iconLabelDisable}
                    textColor={themeColors[themeMode].iconLabelDisable}
                  />
                )}
              </DropUtility>
            )}
          </div>
        )}
        <div className="XmlUtilites">
          <XmlUtilities
            utilityState={utilityState}
            readOnly={readOnly}
            handleUtilityState={handleUtilityState}
            setUtilityState={setUtilityState}
            XMLChanged={XMLChanged}
          />
        </div>
      </div>
      <DeleteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={data}
        component={componentEdited}
        path={parent}
      />
      <Modal
        open={showSwitchPopup}
        onClose={() => {
          setShowSwitchPopup(false);
        }}
        onClickPrimaryAction={() => {
          setShowSwitchPopup(false);
          store.dispatch({
            type: "editor/updateXMLState",
            payload: { XMLChanged: false },
          });
          handleClickedItem(
            addToast,
            store,
            skeleton,
            revisionSelected?.filename,
            true,
            false
          );
          setEditorDisabled(true);
        }}
        secondaryAction={true}
        onClickSecondaryAction={() => {
          setShowSwitchPopup(false);
        }}
        primaryLabel="Discard"
        secondaryLabe="Cancel"
        modalTitle="Attention!"
      >
        <div className="ModalContent">
          <div className="message">
            You are currently editing on Code Editor!
          </div>
          <div className="message">Would you like to discard your edits?</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Properties;
