async function handleRenameIconSubmit(
  renameInputValue,
  currentIcon,
  iconsListing,
  setIconsListing,
  setIsRenameActive,
  setIconsSelected,
  addToast,
  renameIcons
) {
  try {
    if (!renameInputValue) {
      addToast("New icon name cannot be empty.", "warn");
      setIsRenameActive(false);
      return;
    }

    if (/[\s\\/:\*\?"<>\|]+/g.test(renameInputValue)) {
      addToast(
        'Icon name cannot contain spaces or the following characters: \\ / : * ? " < > |',
        "warn"
      );
      setIsRenameActive(false);
      return;
    }

    const newIconNameWithExtension = `${renameInputValue}.png`;
    const success = await renameIcons(
      [
        {
          oldFilename: currentIcon.iconName,
          newFilename: newIconNameWithExtension,
        },
      ],
      addToast
    );

    if (success) {
      const updatedIconsListing = iconsListing.map((icon) =>
        icon.iconName === currentIcon.iconName
          ? { ...icon, iconName: newIconNameWithExtension }
          : icon
      );
      setIconsListing(updatedIconsListing);
      setIsRenameActive(false);
      setIconsSelected([]);
    }
  } catch (error) {
    console.error("Error renaming icon", error);
    addToast("Error renaming icon.", "error");
  }
}

export default handleRenameIconSubmit;
