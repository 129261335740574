import { useTheme } from "../../../theme/themeContext"

const useButtonTheme = (buttonType, connected = false) => {
  const { themeMode, themeColors } = useTheme()
  const theme = themeColors[themeMode]

  switch (buttonType) {
    case "MonitorControlMenu-list":
      return {
        color: theme.MonitorButtonColor,
        backgroundColor: theme.MonitorButtonBackground,
        hoveredBackground: theme.MonitorButtonHover,
        hoveredColor: theme.HoverButtonColor,
        ActiveColor: theme.MonitorButtonActiveColor,
        ActiveBackground: theme.MonitorButtonActiveBackground,
        initialActive: true,
        disableClickReadOnly: false,
        withoutInteration: false,
        widthBtn: "8.063rem",
        heightBtn: "2.563rem",
      }
    case "MonitorControlMenu-Btn":
      return {
        color: "white",
        backgroundColor: theme.ButtonSelected,
        hoveredBackground: theme.hoverMenuButtonBackground,
        hoveredColor: "white",
        ActiveColor: "white",
        ActiveBackground: theme.hoverMenuButtonBackground,
        withIcon: true,
        iconColor: "#FFFFFF",
        iconSize: 24,
        widthBtn: "2.813rem",
        heightBtn: "2.813rem",
        borderRadius: 50,
        withoutInteration: false,
      }

    case "LoggerChangeName":
      return {
        color: theme.MonitorButtonActiveColor,
        backgroundColor: theme.MonitorButtonActiveBackground,
        hoveredBackground: theme.MonitorButtonHover,
        hoveredColor: theme.HoverButtonColor,
        ActiveColor: theme.MonitorButtonActiveColor,
        ActiveBackground: theme.MonitorButtonActiveBackground,
        paddingVertical: 0.7,
        paddingHorizontal: 1,
        fontWeight: 500,
        borderRadius: 2,
        lineHeight: 20.85,
        alignSelf: "center",
        borderWidth: "1px",
        disableClickReadOnly: false,
        withoutInteration: false,
        disabledColor: theme.MonitorColorButtonDisabled,
        disabledBackgroundColor: theme.MonitorBackgroundButtonDisabled,
      }

    case "LoggerConnect":
      return {
        color: theme.loggerButtonColor,
        backgroundColor: theme.monitorBg2,
        hoveredBackground: theme.MonitorButtonHover,
        hoveredColor: theme.HoverButtonColor,
        ActiveColor: connected ? theme.MonitorButtonActiveColor : "",
        ActiveBackground: connected ? theme.MonitorButtonActiveBackground : "",
        borderColor: theme.loggerButtonBorder,
        borderWidth: "1px",
        border: true,
        initialActive: false,
        label: "Connect to IMXLOGGER",
        paddingVertical: 0.7,
        paddingHorizontal: 1.25,
        alignSelf: "center",
        disableClickReadOnly: false,
        withoutInteration: false,
        lineHeight: 20.85,
      }

    case "LoggerDisconnect":
      return {
        color: theme.loggerButtonColor,
        backgroundColor: theme.monitorBg2,
        hoveredBackground: theme.MonitorButtonHover,
        hoveredColor: theme.HoverButtonColor,
        ActiveColor: !connected ? theme.MonitorButtonActiveColor : "",
        ActiveBackground: !connected ? theme.MonitorButtonActiveBackground : "",
        borderColor: theme.loggerButtonBorder,
        borderWidth: "1px",
        border: true,
        initialActive: false,
        label: "Disconnect from IMXLOGGER",
        paddingVertical: 0.7,
        paddingHorizontal: 1.25,
        alignSelf: "center",
        disableClickReadOnly: false,
        withoutInteration: false,
        lineHeight: 20.85,
      }

    case "sidebarButton":
      return {
        color: theme.MonitorButtonColor,
        backgroundColor: theme.monitorBg2,
        hoveredBackground: theme.MonitorButtonHover,
        hoveredColor: theme.HoverButtonColor,
        ActiveColor: theme.MonitorButtonActiveColor,
        ActiveBackground: theme.MonitorButtonActiveBackground,
        borderColor: theme.ToolbarBorderButton,
        disableClickReadOnly: false,
        withoutInteration: false,
        initialActive: false,
        border: true,
        borderWidth: "1px",
        label: "Set Stats Threshold Info",
        lineHeight: 20.85,
        paddingVertical: 0.625,
        paddingHorizontal: 1,
        fontWeight: 500,
        borderRadius: 2,
      }

    case "TableControlButton":
      return {
        color: theme.MonitorButtonColor,
        backgroundColor: theme.monitorBg2,
        hoveredBackground: theme.MonitorButtonHover,
        hoveredColor: theme.HoverButtonColor,
        ActiveColor: theme.MonitorButtonActiveColor,
        ActiveBackground: theme.MonitorButtonActiveBackground,
        borderColor: theme.ToolbarBorderButton,
        disableClickReadOnly: false,
        withoutInteration: false,
        initialActive: false,
        borderWidth: "1px",
        borderLeft: true,
        lineHeight: 20.85,
        paddingVertical: 0.938,
        paddingHorizontal: 1.25,
        fontWeight: 500,
      }

    case "ThresholdForm":
      return {
        color: theme.MonitorButtonColor,
        backgroundColor: theme.monitorBg2,
        hoveredBackground: theme.MonitorButtonHover,
        hoveredColor: theme.HoverButtonColor,
        ActiveColor: theme.MonitorButtonActiveColor,
        ActiveBackground: theme.MonitorButtonActiveBackground,
        borderColor: theme.emailButtonBorderColor,
        disableClickReadOnly: false,
        withoutInteration: false,
        initialActive: false,
        borderWidth: "1px",
        border: true,
        lineHeight: 20.85,
        paddingVertical: 0.625,
      }

    default:
      return {}
  }
}

export default useButtonTheme
