// ThresholdForm.jsx
import "./index.scss"
import React from "react"
import useInput from "../../../../customHooks/useInput"
import InputComponent from "./components/StatsInputs/index"
import CustomButton from "../../../../../../components/CustomButton"
import useButtonTheme from "../../../../customHooks/useButtonTheme"
import EmailInput from "./components/EmailInput/index"

const ThresholdForm = ({ showModal, setShowModal }) => {
  const cpu = useInput("")
  const ram = useInput("")
  const hdd = useInput("")
  const submit = useInput(false)
  const ThresholdForm = useButtonTheme("ThresholdForm")
  const buttonConfigs = [
    {
      label: "Submit",
      isActive: submit,
      widthBtn: "62%",
    },
    {
      label: "Cancel",
      isActive: !submit,
      widthBtn: "34%",
      onClick: () => setShowModal(false),
    },
  ]

  const inputConfigs = [
    {
      label: "CPU Threshold",
      type: "number",
      name: "cpuThreshold",
      inputHook: cpu,
    },
    {
      label: "RAM Threshold",
      type: "number",
      name: "ramThreshold",
      inputHook: ram,
    },
    {
      label: "HDD Threshold",
      type: "number",
      name: "hddThreshold",
      inputHook: hdd,
    },
  ]

  const handleFormSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <form className='threshold-form-container' onSubmit={handleFormSubmit}>
      <EmailInput />
      {inputConfigs.map((input) => (
        <InputComponent
          key={input.name}
          label={input.label}
          type={input.type}
          name={input.name}
          {...input.inputHook.bind}
          showLabel={input.inputHook.isFocused}
        />
      ))}
      <div className='form-btn'>
        {buttonConfigs.map((button) => (
          <CustomButton
            key={button.label}
            isActive={button.isActive}
            label={button.label}
            widthBtn={button.widthBtn}
            onClick={button.onClick}
            {...ThresholdForm}
          />
        ))}
      </div>
    </form>
  )
}

export default ThresholdForm
