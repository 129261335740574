import React, { useContext, useEffect, useState } from "react";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { useSelector, useStore } from "react-redux";
import "./index.scss";
import imxtools from "imxtools";
import { navigateTo } from "../../services/navigationMiddleware";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { ComponentsLibraries } from "../../../../../services/common";
import DropTargetModule from "../../services/DropTargetModule";
import { arraysAreEqual } from "../../../../../../../utils/util";
import { getLabelByLanguage } from "../../../../../../../utils/getLabelByLanguage";
import DragItemApp from "../../../../ComponentsLibrary/DragItemApp";
import { AppContext } from "../../../../../services/AppContext";
import { isSelectedComponent } from "../../services/isSelectedComponent";

function Module({
  data = {},
  indexModule = "",
  path = [],
  numModule,
  readOnly = true,
  currentPage,
  pages,
  setOpenModuleMatrix = () => {},
  setModuleData = () => {},
}) {
  const [openModule, setOpenModule] = useState(false);
  const [hoverStates, setHoverStates] = useState([]);
  const [listApps, setListApps] = useState([]);
  const [theItem, setTheItem] = useState(false);
  const [moduleContainerHovered, setModuleContainerHovered] = useState(false);
  let store = useStore();
  const { dragParentHovered } = useContext(AppContext);

  const handleMouseEnter = (index) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = true;
    setHoverStates(updatedHoverStates);
  };


  const handleMouseLeave = (index) => {
    const updatedHoverStates = [...hoverStates];
    updatedHoverStates[index] = false;
    setHoverStates(updatedHoverStates);
  };

  let currentPath = useSelector(
    (state) => state?.entities?.editor?.parentToEdit
  );

  let language = useSelector((state) => state?.entities?.deviceview?.language);

  let view = useSelector(
    (state) => state?.entities?.deviceview?.settings?.view || "row"
  );

  const handleNavigateTo = (
    event,
    indexApp,
    indexModule,
    app,
    data,
    numApp
  ) => {
    event.stopPropagation();
    const modulePath =
      numModule === 1
        ? [...path, "module"]
        : [...path, "module", indexModule.toString()];
    const newPath =
      numApp === 1
        ? [...modulePath, app]
        : [...modulePath, app, indexApp.toString()];
    navigateTo(store, "List", { data, path: newPath });
  };

  const handleOpenModule = (event, open = false) => {
    if (open) {
      if (view?.toLowerCase() === "row") {
        setOpenModule(!openModule);
      } else {
        setModuleData({
          label: data?.label,
          numModule: numModule,
          path: path ?? [],
          apps: listApps,
          indexModule: indexModule,
          onMouseEnter: () => setModuleContainerHovered(true),
          onMouseLeave: () => setModuleContainerHovered(false),
        });
        setOpenModuleMatrix(true);
      }
    }
    handleComponentProp(
      event,
      numModule === 1 ? null : indexModule?.toString(),
      data,
      path,
      store,
      "module"
    );
  };

  useEffect(() => {
    setModuleData({
      label: data?.label,
      numModule: numModule,
      path: path ?? [],
      apps: listApps,
      indexModule: indexModule,
      onMouseEnter: () => setModuleContainerHovered(true),
      onMouseLeave: () => setModuleContainerHovered(false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listApps]);

  useEffect(() => {
    if (numModule === 1) {
      if (arraysAreEqual(dragParentHovered, [...path, "module"])) {
        setOpenModule(true);
      }
    } else if (
      arraysAreEqual(dragParentHovered, [
        ...path,
        "module",
        indexModule?.toString(),
      ])
    ) {
      setOpenModule(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragParentHovered]);

  useEffect(() => {
    setListApps(imxtools?.toArray(data?.app));
  }, [data]);

  const dropPath =
    numModule === 1
      ? [...path, "module"]
      : [...path, "module", indexModule?.toString()];

  return (
    <DropTargetModule
      className={`${
        view === "row" ? "DropTargetModule" : "DropTargetModule-matrix"
      } ${
        isSelectedComponent(readOnly, dropPath, currentPath) &&
        "selectedComponent"
      } ${
        listApps?.length === 0 &&
        (view === "row"
          ? "DropTargetModule-empty"
          : "DropTargetModule-empty-matrix")
      } `}
      dropPath={dropPath}
      accept={Object.keys(ComponentsLibraries?.module)}
      dropTargetName={"module"}
      indexModule={indexModule}
      currentPage={currentPage}
      pages={pages}
      onMouseEnter={() => setModuleContainerHovered(true)}
      onMouseLeave={() => setModuleContainerHovered(false)}
    >
      <div
        className={`${view === "row" ? "module-card" : "module-card-matrix"} ${
          openModule && "openModule"
        } ${
          isSelectedComponent(readOnly, dropPath, currentPath)
            ? !openModule
              ? "selectedCloseModule"
              : "selectedOpenModule"
            : ""
        }`}
        onClick={(e) => handleOpenModule(e)}
        onDoubleClick={(e) => handleOpenModule(e, true)}
        onMouseEnter={() => handleMouseEnter(indexModule)}
        onMouseLeave={() => handleMouseLeave(indexModule)}
      >
        <div className={`app-icon ${openModule ? "open" : "close"}`}>
          <Icon
            icon={data?.icon ? data?.icon : "notoper"}
            size={view === "row" ? 20 : 45}
            color={view !== "row" ? "white" : ""}
          />
        </div>
        <div className={`app-title ${openModule ? "open" : "close"}`}>
          <div className="title">
            {data?.label ? getLabelByLanguage(language, data) : "Add label"}
          </div>
        </div>
        {view === "row" && (
          <div className={`icon-chevron ${openModule ? "open" : "close"}`}>
            <Icon icon={openModule ? "chevron-up" : "chevron-down"} size={15} />
          </div>
        )}
      </div>
      {view?.toLowerCase() === "row" && (
        <div
          className={`apps-module ${readOnly ? "" : "hovered"} ${
            isSelectedComponent(readOnly, dropPath, currentPath) &&
            "selectedAppsModule"
          }`}
        >
          {openModule &&
            (listApps?.length > 0
              ? listApps?.map((app, indexApp) => {
                  const lastApp =
                    imxtools?.toArray(data?.app)?.length - 1 === indexApp;
                  let numApp = imxtools?.toArray(data?.app ?? [])?.length;
                  const dragPath =
                    numModule === 1
                      ? numApp === 1
                        ? [...path, "module", "app"]
                        : [...path, "module", "app", indexApp?.toString()]
                      : numApp === 1
                      ? [...path, "module", indexModule?.toString(), "app"]
                      : [
                          ...path,
                          "module",
                          indexModule?.toString(),
                          "app",
                          indexApp?.toString(),
                        ];
                  return (
                    <DragItemApp
                      accept={["app"]}
                      className={`drag-apps-module ${
                        lastApp && "lastAppModule"
                      } ${
                        isSelectedComponent(readOnly, dragPath, currentPath) &&
                        "selectedComponent"
                      }`}
                      key={indexApp}
                      dataSorted={listApps}
                      sort={true}
                      app={app}
                      index={indexApp}
                      theItem={theItem}
                      listApps={listApps}
                      setListApps={setListApps}
                      setTheItem={setTheItem}
                      hoveredComponent={"module"}
                      component={"app"}
                      dragData={app}
                      dragPath={dragPath}
                      dragParentPath={
                        numModule === 1
                          ? [...path, "module"]
                          : [...path, "module", indexModule?.toString()]
                      }
                    >
                      <div
                        className="app-module"
                        onClick={(e) =>
                          handleComponentProp(
                            e,
                            numApp === 1 ? null : indexApp?.toString(),
                            app,
                            numModule === 1
                              ? [...path, "module"]
                              : [...path, "module", indexModule?.toString()],
                            store,
                            "app"
                          )
                        }
                        onDoubleClick={(event) =>
                          handleNavigateTo(
                            event,
                            indexApp,
                            indexModule,
                            "app",
                            app,
                            numApp
                          )
                        }
                      >
                        <div className="icon-app-module">
                          <Icon
                            className="notoperr"
                            icon={app?.icon ? app?.icon : "notoper"}
                            size={20}
                          />
                        </div>
                        <div className="title-app-module">
                          <div className="title">
                            {app?.label
                              ? getLabelByLanguage(language, app)
                              : `Add label`}
                          </div>
                        </div>
                      </div>
                    </DragItemApp>
                  );
                })
              : !readOnly && (
                  <div className="drop-apps-empty-module">
                    <div className="drag-app-empty-module">{readOnly ? "No Apps here" : "Drop Apps here"}</div>
                  </div>
                ))}
        </div>
      )}
    </DropTargetModule>
  );
}

export default Module;
