const deleteItem = (store, currentPath = [], SetNoComponent) => {
  try {
    if (currentPath?.length === 1) {
      return;
    }
    store.dispatch({
      type: "explorer/deleteItem",
      payload: {
        currentPath: currentPath,
      },
    });
    store.dispatch({
      type: "editor/dataAdded",
      payload: { data: {}, parent: [] },
    });
  } catch (error) {
    console.error("error-", error);
  }
};

export default deleteItem;
