import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../../../../../theme/themeContext";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import {
  deletePropertyByIndex,
  updateName,
} from "../../../services/UtilityServices";
import { useSelector } from "react-redux";
import "./index.scss";
import Editor from "../../../../../XmlSide/AceEditor";

const UtilityProperty = ({
  Key = "",
  objectKey = "key",
  Value = "",
  objectValue = "value",
  data = {},
  setData,
  index,
  maxRows = 4,
  multiline = false,
  disabled = false,
  readOnly = true,
  actions = true,
  disableKey = false,
  inputType = "text",
  minHeightExp = "2.5rem",
  mode = "javascript",
}) => {
  const [value, setValue] = useState(Value);
  const [key, setKey] = useState(Key);
  const { themeMode, themeColors } = useTheme();

  let codeEditor = useSelector((state) =>
    state?.entities?.codeeditor ? state?.entities?.codeeditor : {}
  );

  let { fontSize, theme } = codeEditor;

  useEffect(() => {
    setKey(Key);
    setValue(Value);
  }, [Key, Value]);

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setKey(newName);
    updateName(data, setData, index, objectKey, newName);
  };

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    updateName(data, setData, index, objectValue, newValue);
  };

  const handleEditorChange = (newValue) => {
    setValue(newValue);
    updateName(data, setData, index, objectValue, newValue);
  };

  return (
    <>
      {readOnly ? (
        <input
          placeholder={objectKey}
          className="input"
          value={key}
          readOnly
          style={{
            borderColor: "#ccc",
            outline: "none",
          }}
          required
          onFocus={(event) => {
            event.target.style.borderColor = themeColors[themeMode].logo;
          }}
          onBlur={(event) => {
            event.target.style.borderColor = "#ccc";
          }}
        />
      ) : (
        <input
          placeholder={objectKey}
          className="input2"
          value={key}
          onChange={disabled || disableKey ? null : handleNameChange}
          style={{
            borderColor: "#ccc",
            outline: "none",
          }}
          onFocus={(event) => {
            event.target.style.borderColor = themeColors[themeMode].logo;
          }}
          onBlur={(event) => {
            event.target.style.borderColor = "#ccc";
          }}
        />
      )}
      {inputType?.toLowerCase() === "expression" ? (
        <Editor
          theme={theme}
          fontSize={fontSize}
          value={value}
          onChange={readOnly || disabled ? null : handleEditorChange}
          readOnly={readOnly}
          property={true}
          placeholder={"Write expression"}
          minHeight={minHeightExp}
          language={mode}
        />
      ) : (
        <input
          placeholder={objectValue}
          className="input2"
          value={value}
          onChange={readOnly || disabled ? null : handleValueChange}
          readOnly={readOnly}
          maxRows={maxRows}
          multiline={multiline?.toString()}
          style={{
            borderColor: "#ccc",
            outline: "none",
          }}
          onFocus={(event) => {
            event.target.style.borderColor = themeColors[themeMode].logo;
          }}
          onBlur={(event) => {
            event.target.style.borderColor = "#ccc";
          }}
        />
      )}
      {!readOnly && actions && (
        <div className="buttons">
          <div
            className="deleteBtn"
            onClick={() => deletePropertyByIndex(data, index, setData)}
          >
            <Icon icon="delete" size={18} />
          </div>
        </div>
      )}
    </>
  );
};

export default UtilityProperty;
