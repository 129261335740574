import React from "react";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import "./index.scss";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useStore } from "react-redux";

function HeaderClassification({ data = {}, path = [] }) {
  let store = useStore();
  return (
    <div
      className="headerClassification"
      onClick={(event) =>
        handleComponentProp(event, null, data, path, store, "header")
      }
    >
      <div
        className="event-classification"
        onClick={(event) =>
          handleComponentProp(
            event,
            null,
            data?.event,
            [...path, "header"],
            store,
            "event"
          )
        }
      >
        <Icon icon="add" size={17} />
      </div>
    </div>
  );
}

export default HeaderClassification;
