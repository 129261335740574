import React, { useContext, useEffect, useMemo, useState } from "react";
import { useStore, useSelector } from "react-redux";
import { navigateBack } from "../../../services/navigationMiddleware";
import Icon from "../../../../../../../../components/CustomIcon/Icon";
import { getIn } from "lodash-redux-immutability";
import Toolbar from "../../Toolbar";
import Modal from "../../Modal";
import Sidebar from "../../Sidebar";
import Swipe from "../../Swipe";
import imxtools from "imxtools";
import * as Views from "./Views";
import ListView from "./Views/List View";
import Fab from "../../Fab";
import "./index.scss";
import { handleComponentProp } from "../../../services/handleComponentProperties";
import HeaderClassification from "../../HeaderClassification";
import DropTargetBody from "../../DropTargetBody";
import { AppContext } from "../../../../../../services/AppContext";
import { getLabelByLanguage } from "../../../../../../../../utils/getLabelByLanguage";
import { isSelectedComponent } from "../../../services/isSelectedComponent";
import InspectionModalOld from "../../InspectionModalOld";
import InspectionModal from "../../InspectionModal";

const ListScreen = () => {
  const [data, setData] = useState({});
  const [listData, setListData] = useState({});
  const [pathApp, setPathApp] = useState([]);
  const [pathList, setPathList] = useState([]);
  const [forms, setForms] = useState([]);
  const [hforms, setHforms] = useState([]);
  const [currentView, setCurrentView] = useState("ListView");
  const [listLength, setLlistLength] = useState("--");
  const [openModalSidebar, setOpenModalSidebar] = useState(false);
  const [openModalSwipe, setOpenModalSwipe] = useState(false);
  const {
    setTargetRefEvent,
    openInspection,
    setOpenInspection,
    setBodyHeader,
  } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  let store = useStore();

  const { skeleton, language, parentToEdit, navigationPath } = useSelector(
    (state) => ({
      skeleton: state?.entities?.explorer?.skeleton || {},
      language: state?.entities?.deviceview?.settings?.language || "en",
      parentToEdit: state?.entities?.editor?.parentToEdit || [],
      navigationPath: state?.entities?.deviceview?.navigationPath || [],
    })
  );

  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  useEffect(() => {
    const { path } = navigationPath?.[navigationPath.length - 1]?.props;

    const AppData = getIn(skeleton ? skeleton : {}, path);
    setData(AppData);
    setPathApp(path);

    if (AppData?.list) {
      const ListData = getIn(skeleton ? skeleton : {}, [...path, "list"]);
      setListData(ListData);
      setPathList([...path, "list"]);
    }
  }, [navigationPath, skeleton]);

  useEffect(() => {
    const listData = getIn(skeleton ? skeleton : {}, pathList);
    const forms = imxtools?.toArray(listData?.form ? listData?.form : []);
    const hforms = imxtools?.toArray(listData?.hform ? listData?.hform : []);

    setForms(forms);
    setHforms(hforms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleRenderView = (event, view) => {
    event.stopPropagation();
    setCurrentView(view);
  };

  const handleOpenActions = (event, open) => {
    event.stopPropagation();
    setTargetRefEvent(event);
    setOpenModalSwipe(open);
  };

  const handleOpenSidebar = (event, open) => {
    event.stopPropagation();
    setOpenModalSidebar(open);
  };

  const RenderViewComponent = (props) => {
    const TargetComponent = Views[currentView] || ListView;
    return <TargetComponent {...props} />;
  };

  let viewProps = {
    data: data,
    path: pathList,
    listData: listData,
    readOnly: readOnly,
    setLlistLength,
    handleOpenActions,
    currentPath: parentToEdit,
    setIsOpen: setIsOpen,
  };

  const closeModal = () => {
    setOpenInspection(false);
  };

  const renderList = useMemo(() => {
    return (
      <React.Fragment>
        {data?.list ? (
          <DropTargetBody
            className="drop-list-zone"
            accept={[
              "section",
              "fab",
              "toolbar",
              "detail",
              "swipe",
              "body",
              "calendar",
            ]}
            dragParent={pathList}
          >
            <div
              className="headerList"
              style={{
                flexDirection:
                  language?.toLowerCase() === "ar" && "row-reverse",
              }}
            >
              <div
                className="goBack"
                onClick={() => {
                  navigateBack(store);
                  setBodyHeader(false);
                }}
              >
                <Icon
                  icon={
                    language?.toLowerCase() === "ar"
                      ? "chevron-right"
                      : "chevron-left"
                  }
                  size={20}
                />
              </div>
              <div
                className={`appTitle ${
                  isSelectedComponent(readOnly, pathList, parentToEdit) &&
                  "selectedComponent"
                }`}
                onClick={(e) =>
                  handleComponentProp(e, null, listData, pathApp, store, "list")
                }
                style={{
                  minWidth:
                    listData?.hasImages?.toLowerCase() === "true" &&
                    listData.header &&
                    !readOnly &&
                    "calc(100% - 96px)",
                  maxWidth:
                    listData?.hasImages?.toLowerCase() === "true" &&
                    listData.header &&
                    !readOnly &&
                    "calc(100% - 96px)",
                }}
              >
                {" "}
                <>
                  <span className="List-label">
                    {listData?.label
                      ? getLabelByLanguage(language, listData)
                      : "Add label"}
                  </span>{" "}
                  <span className="list-length">
                    {listData?.body ? listLength : "N/A"}
                  </span>
                </>
              </div>
              {listData?.hasImages?.toLowerCase() === "true" &&
                listData.header && (
                  <div className="header-clssification">
                    <HeaderClassification
                      data={listData?.header}
                      path={pathList}
                    />
                  </div>
                )}
              {(listData?.hasImages?.toLowerCase() !== "true" ||
                (listData?.hasImages?.toLowerCase() === "true" &&
                  listData.header &&
                  !readOnly)) && (
                <div
                  className="goBack"
                  onClick={(e) => {
                    handleComponentProp(
                      e,
                      null,
                      listData,
                      pathApp,
                      store,
                      "list"
                    );
                    setOpenModalSidebar(true);
                  }}
                >
                  <Icon icon="list-mode" size={20} />
                </div>
              )}
            </div>
            {(listData?.search?.toLowerCase() === "true" ||
              listData?.toolbar) && (
              <div className="toolbar-container">
                <Toolbar
                  data={listData?.toolbar}
                  path={pathList}
                  searchStatus={listData?.search?.toLowerCase() === "true"}
                  readOnly={readOnly}
                  currentPath={parentToEdit}
                  listData={listData}
                />
              </div>
            )}
            {listData?.path && (
              <div className="path-clssification">
                <HeaderClassification data={listData?.header} path={pathList} />
              </div>
            )}
            <div
              onClick={(e) => {
                handleComponentProp(e, null, listData, pathApp, store, "list");
                setBodyHeader(false);
              }}
              className={
                listData?.toolbar || listData?.search?.toLowerCase() === "true"
                  ? "list-body-toolbar"
                  : listData?.body
                  ? "list-body"
                  : "list-body-empty"
              }
            >
              {RenderViewComponent(viewProps)}
            </div>
          </DropTargetBody>
        ) : (
          <div className="drop-list-zone">
            <div className="headerList">
              <div className="goBack" onClick={() => navigateBack(store)}>
                <Icon icon="chevron-left" size={20} />
              </div>
            </div>
            <DropTargetBody
              accept={["list"]}
              dragParent={pathApp}
              className="drop-empty-list-zone"
            >
              {/* <div className='empty-list'>Drop List here</div> */}
              <div className="empty-list">
                {readOnly ? "No List here" : "Drop List here"}
              </div>
            </DropTargetBody>
          </div>
        )}

        {/* Fab */}
        {listData?.fab && (
          <Fab
            data={listData?.fab}
            readOnly={readOnly}
            path={pathList}
            forms={forms}
            currentPath={parentToEdit}
          />
        )}

        {/* Sidebar */}
        <Modal openModal={openModalSidebar}>
          <Sidebar
            data={imxtools?.toArray(listData?.whereclause)}
            fullData={data}
            handleOpenSidebar={handleOpenSidebar}
            handleListClick={(e) =>
              handleComponentProp(e, null, data, pathList, store, "list")
            }
            handleRenderView={handleRenderView}
            currentView={currentView}
            path={pathList}
            readOnly={readOnly}
            currentPath={parentToEdit}
            classification={listData?.hasImages?.toLowerCase() === "true"}
            maximomedia={imxtools?.toArray(listData?.maximomedia || [])}
          />
        </Modal>

        {/* Swipe */}
        <Modal openModal={openModalSwipe}>
          <Swipe
            data={listData?.swipe}
            path={pathList}
            handleOpenActions={handleOpenActions}
            readOnly={readOnly}
            forms={[...forms, ...hforms]}
            formsData={forms}
            hformsData={hforms}
            numForms={forms?.length}
            currentPath={parentToEdit}
          />
        </Modal>
        <Modal openModal={openInspection} tab={true}>
          {listData?.inspection?.version === "V2" ? (
            <InspectionModalOld
              closeModal={closeModal}
              data={listData?.inspection}
              path={[...pathList, "inspection"]}
            />
          ) : (
            <InspectionModal
              closeModal={closeModal}
              data={listData?.inspection}
              path={[...pathList, "inspection"]}
            />
          )}
        </Modal>
      </React.Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewProps]);
  return renderList;
};

export default ListScreen;
