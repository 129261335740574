import React, { useState } from "react";
import { Formik } from "formik";
import logoImage from "./../../../../../../../../assets/maxapps-assets/logo.png";
import ModalLanguageSettings from "../../ModalLanguageSettings";
import Modal from "../../Modal";
import { useSelector, useStore } from "react-redux";
import ModalLoadingLogin from "../../ModalLoadingLogin";
import LanguageSetting from "../../LanguageSetting";
import CustomInputField from "./components/CustomInputField";
import { useDeviceTheme } from "../../../../../../../../DeviceTheme/DeviceThemeContext";
import {
  enableSocialMedia,
  checkLanguageModal,
  displayOptions,
  displayIpDomain,
} from "../../../services/config";
import loginBg from "./../../../../../../../../assets/maxapps-assets/loginBg.jpg";
import "./index.scss";
import { useLanguageDevice } from "../../../services/DeviceTranslate/LanguageContext";
import Icon from "../../../../../../../../components/CustomIcon/Icon";

function LoginScreen() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [displaySplash, setDisplaySplash] = useState(false);
  const [openTwoLanguageModal, setOpenTwoLanguageModal] = useState(false);
  const [initValues, setInitValues] = useState({
    userName: "",
    password: "",
    domain: "",
  });
  let store = useStore();
  const { theme, themes } = useDeviceTheme();
  const { translate } = useLanguageDevice();

  const useCustomBackground = process.env.REACT_APP_USE_CUSTOM_BACKGROUND;

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  const closeModal = () => {
    setIsOpen(false);
  };

  const navigateToHome = () => {
    setDisplaySplash(true);

    setTimeout(async () => {
      try {
        store.dispatch({
          type: "deviceview/loginAct",
        });

        setDisplaySplash(false);
      } catch (error) {
        console.error("Error during navigation to home:", error);
      }
    }, 3000);
  };

  const languageAction = () => {
    if (checkLanguageModal === "true") {
      setOpenTwoLanguageModal(true);
    } else {
      setIsOpen(true);
    }
  };

  const closeModalTwoLang = () => {
    setOpenTwoLanguageModal(false);
  };

  return (
    <React.Fragment>
      <div
        className="login-screen-container"
        style={{
          background:
            useCustomBackground &&
            useCustomBackground?.toLowerCase() === "true" &&
            `url(${loginBg}) center/cover no-repeat`,
        }}
      >
        <div className="login-header">
          <div className="logo-container">
            <img src={logoImage} alt="logo" className="logo-style" />
          </div>
        </div>
        <div className="login-body">
          <Formik
            initialValues={initValues}
            validate={(values) => {
              const errors = {};

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                navigateToHome();
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              resetForm,
            }) => (
              <form className="form-container" onSubmit={handleSubmit}>
                <CustomInputField
                  name={"userName"}
                  value={values?.userName}
                  placeHolder={translate("Username")}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  setInitValues={setInitValues}
                  initValues={values}
                  resetForm={resetForm}
                  language={language}
                />
                <CustomInputField
                  name={"password"}
                  value={values?.password}
                  placeHolder={translate("Password")}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  setInitValues={setInitValues}
                  initValues={values}
                  resetForm={resetForm}
                  language={language}
                />
                {displayOptions !== "true" && (
                  <span className="option-txt-style">
                    {translate("Options")}
                  </span>
                )}
                {displayIpDomain === "true" && (
                  <CustomInputField
                    name={"domain"}
                    value={values?.domain}
                    placeHolder={"Domain/IP address"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    setInitValues={setInitValues}
                    initValues={values}
                    resetForm={resetForm}
                    language={language}
                  />
                )}
                <button className="btn-login" type="submit">
                  {translate("Login")}
                </button>
                {enableSocialMedia === "true" && (
                  <>
                    <span
                      style={{
                        fontSize: 12,
                        marginTop: 10,
                        color: themes?.[theme]?.loginOptions,
                      }}
                    >
                      Or
                    </span>
                    <div className="social-media-btns-container">
                      <div className="google-facebook-btns">
                        <button className="google-btn">
                          <i
                            className="bx bxl-google"
                            style={{
                              color: "#6A549E",
                              fontSize: 16,
                              marginRight: 5,
                            }}
                          ></i>
                          google
                        </button>
                        <button className="facebook-btn">
                          <i
                            className="bx bxl-facebook"
                            style={{
                              color: "#6A549E",
                              fontSize: 16,
                              marginRight: 5,
                            }}
                          ></i>
                          facebook
                        </button>
                      </div>
                      <div className="email-phone-btns">
                        <button className="email-btn">
                          <i
                            className="bx bxs-envelope"
                            style={{
                              color: "#6A549E",
                              fontSize: 16,
                              marginRight: 5,
                            }}
                          ></i>
                          E-mail
                        </button>
                        <button className="phone-btn">
                          <i
                            className="bx bxs-phone"
                            style={{
                              color: "#6A549E",
                              fontSize: 16,
                              marginRight: 5,
                            }}
                          ></i>
                          Phone
                        </button>
                      </div>
                      <button className="smal-btn">
                        <i
                          className="bx bxs-server"
                          style={{
                            color: "#6A549E",
                            fontSize: 16,
                            marginRight: 5,
                          }}
                        ></i>
                        SAML
                      </button>
                    </div>
                  </>
                )}
              </form>
            )}
          </Formik>
        </div>
        <div
          className="login-footer"
          style={{
            flexDirection:
              language?.toLowerCase() === "ar" ? "row-reverse" : "row",
          }}
        >
          <div className="privacy-container">
            <div className="privacy-container">
              <span className="privacy-txt">{translate("PrivacyPolicy")}</span>
              <span className="about-txt">{translate("About us")}</span>
            </div>
          </div>
          <div
            className="language-container"
            style={{
              display: "flex",
              flexDirection: language === "ar" ? "row-reverse" : "row",
            }}
            onClick={() => languageAction()}
          >
            <span className="language-value-txt">{language.toUpperCase()}</span>
            <Icon icon="language1" className="LanguageBtn" size={20} />
          </div>
        </div>
      </div>
      <Modal openModal={openTwoLanguageModal} tab={true} isForm={true}>
        <LanguageSetting closeModalTwoLang={closeModalTwoLang} />
      </Modal>
      <Modal openModal={modalIsOpen} tab={true} isForm={true}>
        <ModalLanguageSettings closeModal={closeModal} />
      </Modal>
      <Modal
        openModal={displaySplash}
        tab={true}
        isSidebar={true}
        isForm={true}
      >
        <ModalLoadingLogin setDisplaySplash={setDisplaySplash} />
      </Modal>
    </React.Fragment>
  );
}
export default LoginScreen;
