import React, { useRef } from "react";
import Vector from "../assets/img/Vector.svg";
import useToasts from "../../../../components/CustomToast/useToasts";
import mergeArray from "../../services/helpers/mergeArrays";
import uploadIconToServer from "../../services/uploadIconToServer";
import { useDropzone } from "react-dropzone";
import "./index.scss";

function UploadIcons({ setIconsListing, iconMaxSize }) {
  const { addToast } = useToasts();
  const inputRef = useRef(null);

  async function handleUpload(files) {
    try {
      const formdata = new FormData();
      for (let index = 0; index < files?.length; index++) {
        const file = files[index];

        if (file.type !== "image/png" && !file.name.endsWith(".png")) {
          addToast("Only PNG files are supported  " + file.name, "warn");
          return;
        }
        if (file.size > iconMaxSize) {
          addToast("Icon size is too large for " + file.name, "warn");
          return;
        }
        formdata.append("files", file);
      }

      const uploadedFiles = await uploadIconToServer(formdata, addToast);
      setIconsListing((oldIconsList) => {
        return mergeArray(oldIconsList, uploadedFiles, "iconName");
      });
    } catch (error) {
      addToast("Error occurred while uploading icons", "error");
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleUpload,
    accept: ".png",
    noClick: true, // Prevents the file dialog from opening on click
  });

  function openDialog() {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  return (
    <div className="mainContainer-icon">
      <div className="upload-mainContainer">
        <input {...getInputProps()} ref={inputRef} />
        <img id="vector" src={Vector} alt="Upload Icon" />
        <p id="text-L">No Icons Yet.</p>
        <div className="upload-instructions">
          <p id="text-M">Drop your Icons here or</p>
          <button className="uploadButton" onClick={openDialog}>
            Upload Icons
          </button>
          <p id="text-S">Supports only .png</p>
        </div>
      </div>
    </div>
  );
}

export default UploadIcons;
