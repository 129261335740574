import React, { useState } from "react";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import Modal from "../../../../Modal";
import DisconnectModal from "../../../../DisconnectModal";
import SettingModule from "./components/SettingModule";
import Icon from "../../../../../../../../../../components/CustomIcon/Icon";
import { useLanguageDevice } from "../../../../../services/DeviceTranslate/LanguageContext";
import SwipeTheme from "../../../../SwipeTheme";
import SwipeLanguage from "../../../../SwipeLanguage";

function Settings() {
  const [login, setLogin] = useState(true);
  const [notificationPer, setNotificationPer] = useState(true);
  const [locationPer, setLocationPer] = useState(true);
  const [styles, setStyles] = useState(false);
  const [viewModule, setViewModule] = useState(false);
  const [languageModule, setLanguageModule] = useState(false);
  const [openModal, setIsOpen] = useState(false);
  const { translate } = useLanguageDevice();
  const [openModalSwipe, setOpenModalSwipe] = useState(false)
  const [openModalSwipeLanguage, setopenModalSwipeLanguage] = useState(false)



  let store = useStore();

  const settings = useSelector(
    (state) => state?.entities?.deviceview?.settings || {}
  );

  const { theme, view, font, appearance, language } = settings;

  const navigateToLogin = () => {
    try {
      store.dispatch({
        type: "deviceview/logoutAct",
        payload: {},
      });
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleClickView = () => {
    store.dispatch({
      type: "deviceview/setView",
    });
  };

  const openDisconnectModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleOpenActions = (event, open) => {
    event.stopPropagation()
    setOpenModalSwipe(open)
  }

  const handleOpenActionsLanguage = (event, open) => {
    event.stopPropagation()
    setopenModalSwipeLanguage(open)
  }

  return (
    <div className="settings-high-container">
      <div className="settings-container">
        <SettingModule
          title={translate("Login")}
          status={login}
          setStatus={setLogin}
        >
          <div
            className={`login-setting ${
              language === "ar" ? "login-setting-ar" : ""
            }`}
          >
            <div className="username">
              <span className="username-label">{translate("Username")} </span>
              <span className="username-value">Jhon Doe</span>
            </div>
            <div
              className="domain"
              style={{
                textAlign: language === "ar" && "right",
              }}
            >
              <span className="domain-label">
                {translate("Domain / IP address")}
              </span>
              <span className="domain-value">113.161.23.191:22002</span>
            </div>
          </div>
        </SettingModule>
        <SettingModule
          title={translate("Notification permission")}
          headerIcon={notificationPer ? "check" : "error"}
          status={notificationPer}
          setStatus={setNotificationPer}
          dispbleOpen={true}
          iconSize={15}
          iconColor={notificationPer ? "green" : "red"}
        />
        <SettingModule
          title={translate("LocationPermission")}
          headerIcon={locationPer ? "check" : "error"}
          status={locationPer}
          setStatus={setLocationPer}
          dispbleOpen={true}
          iconSize={15}
          iconColor={locationPer ? "green" : "red"}
        />
        <SettingModule
          title={translate("Styles")}
          status={styles}
          setStatus={setStyles}
        >
          <div className="styles-settings">
            <div
              className="theme"
              style={{
                flexDirection: language === "ar" && "row-reverse",
              }}
              onClick={(event) => handleOpenActions(event, true)}

            >
              <div
                className="theme-left"
                style={{
                  alignItems: language === "ar" && "flex-end",
                }}
              >
                <span className="theme-title">{translate("Theme")}</span>
                <span className="theme-value">{theme}</span>
              </div>
              <div className="theme-right"></div>
            </div>
            <div
              className="font"
              style={{
                flexDirection: language === "ar" && "row-reverse",
              }}
            >
              <div
                className="font-container"
                style={{
                  alignItems: language === "ar" && "flex-end",
                }}
              >
                <span className="font-title">{translate("Font")}</span>
                <span className="font-value">{font}</span>
              </div>
            </div>
            <div
              className="apparence"
              style={{
                flexDirection: language === "ar" && "row-reverse",
              }}
            >
              <div
                className="apparence-container"
                style={{
                  alignItems: language === "ar" && "flex-end",
                }}
              >
                <span className="apparence-title">
                  {translate("Appearance")}
                </span>
                <span className="apparence-value">{appearance}</span>
              </div>
            </div>
          </div>
        </SettingModule>
        <SettingModule
          title={translate("Views")}
          status={viewModule}
          setStatus={setViewModule}
        >
          <div className="styles-settings">
            <div
              className="view"
              onClick={handleClickView}
              style={{
                flexDirection: language === "ar" && "row-reverse",
              }}
            >
              <div className="view-label">{view}</div>
              <div className="view-icon">
                <Icon
                  icon={view === "row" ? "list-mode" : "metro-mode"}
                  size={24}
                />
              </div>
            </div>
          </div>
        </SettingModule>
        <SettingModule
          title={translate("Language")}
          status={languageModule}
          setStatus={setLanguageModule}
        >
          <div className="styles-settings">
            <div
              className="language"
              onClick={(event) => handleOpenActionsLanguage(event, true)}

              style={{
                justifyContent: language === "ar" && "flex-end",
              }}
            >
              {language.toUpperCase()}
            </div>
          </div>
        </SettingModule>
      </div>
      <div className="logout-footer">
        <div className="logout-btn" onClick={openDisconnectModal}>
          <span>Sign out</span>
          <Icon icon="log-out2" size={22} />
        </div>
      </div>
      <Modal openModal={openModal} tab={true} isSideBar={true}>
        <DisconnectModal
          navigateToLogin={navigateToLogin}
          closeModal={closeModal}
        />
      </Modal>

      <Modal openModal={openModalSwipe} 
      tab={true}>
          <SwipeTheme
            theme={theme}
            settings={settings}
            handleOpenActions={handleOpenActions}
          />
        </Modal>
        <Modal openModal={openModalSwipeLanguage} 
         tab={true}>
          <SwipeLanguage
            settings={settings}
            handleOpenActionsLanguage={handleOpenActionsLanguage}
          />
        </Modal>
    </div>
  );
}

export default Settings;
