import React, { useEffect, useState } from "react";
import "./index.scss";
import Toolbar from "../Toolbar";
import BodyList from "../BodyList";
import Modal from "../Modal";
import Swipe from "../Swipe";
import imxtools from "imxtools";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useSelector, useStore } from "react-redux";
import Relationship from "../Relationship";
import Fab from "../Fab";
import { getLabelByLanguage } from "../../../../../../../utils/getLabelByLanguage";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import DropTargetBody from "../DropTargetBody";

function ListDetail({
  data = {},
  path = [],
  readOnly = false,
  tabLabel = "",
  currentPath = [],
}) {
  const [openModalSwipe, setOpenModalSwipe] = useState(false);
  const [forms, setForms] = useState([]);
  const [hforms, setHforms] = useState([]);
  const [openRelationship, setOpenRelationship] = useState(false);
  let store = useStore();

  const language = useSelector(
    (state) => state?.entities?.deviceview?.settings?.language || "en"
  );

  useEffect(() => {
    const forms = imxtools?.toArray(data?.form ? data?.form : []);
    setForms(forms);
    const hforms = imxtools?.toArray(data?.hform ? data?.hform : []);
    setHforms(hforms);
  }, [data]);

  const handleOpenActions = (event, open) => {
    event.stopPropagation();
    setOpenModalSwipe(open);
  };
  return (
    <DropTargetBody
      className={`ListDetail-zone ${
        isSelectedComponent(readOnly, path, currentPath)
          ? "selectedComponent"
          : !readOnly && "unSelectedComponent"
      }`}
      style={{
        minHeight: !data?.label && "100px",
      }}
      dragParent={path}
      onClick={(e) => handleComponentProp(e, null, data, path, store, "list")}
    >
      <div
        className="ListDetail-container"
        style={{
          alignItems:
            language?.toLowerCase() === "ar" ? "flex-end" : "flex-start",
          padding: (!data?.label || data?.body || data?.toolbar) && "3.5% 0%",
        }}
      >
        {Object?.keys(data)?.length !== 0 ? (
          <React.Fragment>
            {data?.label && (
              <div
                className="relatin-label-container"
                onClick={(e) =>
                  handleComponentProp(e, null, data, path, store, "list")
                }
              >
                <div className="relatin-label">
                  {getLabelByLanguage(language, data)}
                </div>
              </div>
            )}
            {(data?.toolbar || data?.body) && (
              <div
                className="relation-body"
                style={{
                  borderTop: data?.label && "1px solid var(---separatorColor)",
                }}
              >
                {data?.toolbar && (
                  <div className="toolbar-container">
                    <Toolbar
                      data={data?.toolbar}
                      path={path}
                      searchStatus={data?.search?.toLowerCase() === "true"}
                      readOnly={readOnly}
                      relationship={true}
                      currentPath={currentPath}
                    />
                  </div>
                )}
                {data?.body && (
                  <BodyList
                    data={data}
                    listData={data}
                    path={path}
                    currentPath={currentPath}
                    handleOpenActions={handleOpenActions}
                    readOnly={readOnly}
                    relationship={true}
                    setOpenRelationship={setOpenRelationship}
                  />
                )}
              </div>
            )}
            {!data?.body && !data?.toolbar && !data?.label && (
              <DropTargetBody
                dragParent={path}
                className="list-empty"
                onClick={(e) =>
                  handleComponentProp(e, null, data, path, store, "list")
                }
              >
                List
              </DropTargetBody>
            )}
          </React.Fragment>
        ) : (
          <></>
        )}
      </div>

      {data?.detail && (
        <Modal openModal={openRelationship} tab={true}>
          <div className="relationshipModal">
            <div
              className="ReHeader"
              onClick={() => setOpenRelationship(false)}
            >
              <div className="ReLabel">{tabLabel}</div>
            </div>
            <div
              className="ReContent"
              onClick={(e) => {
                handleComponentProp(
                  e,
                  null,
                  data?.detail,
                  path,
                  store,
                  "detail"
                );
              }}
            >
              <Relationship
                data={data?.detail}
                path={[...path, "detail"]}
                setOpenRelationship={setOpenRelationship}
                readOnly={readOnly}
                currentPath={currentPath}
                parentPath={path}
              />
            </div>
          </div>
        </Modal>
      )}
      {data?.fab ? (
        <Fab data={data?.fab} readOnly={readOnly} path={path} forms={forms} />
      ) : data?.list?.fab ? (
        <Fab
          data={data?.list?.fab ? data?.list?.fab : {}}
          readOnly={readOnly}
          path={[...path, "list"]}
          forms={forms}
        />
      ) : null}
      {/* Swipe */}
      <Modal openModal={openModalSwipe} tab={true}>
        <Swipe
          data={data?.swipe}
          path={path}
          handleOpenActions={handleOpenActions}
          readOnly={readOnly}
          forms={[...forms, ...hforms]}
          numForms={forms?.length}
        />
      </Modal>
    </DropTargetBody>
  );
}

export default ListDetail;
