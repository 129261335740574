import React, { useState } from "react";
import { useStore } from "react-redux";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import Tab from "../Tab";
import { handleComponentProp } from "../../services/handleComponentProperties";
import imxtools from "imxtools";
import "./index.scss";
import DropTargetBody from "../DropTargetBody";
import { useDeviceTheme } from "../../../../../../../DeviceTheme/DeviceThemeContext";

function RelationShip({
  data = {},
  path = [],
  setOpenRelationship,
  readOnly = true,
  currentPath = [],
  parentPath = [],
}) {
  const [currentTab, setCurrentTab] = useState(0);
  const { themes, theme } = useDeviceTheme();
  let store = useStore();

  const handleTab = (e, currentTab) => {
    e.stopPropagation();
    const tabs = imxtools?.toArray(data?.tab ?? []);
    setCurrentTab(currentTab);
    const tabData = tabs[currentTab] ? tabs[currentTab] : data;
    const tabPath =
      tabs?.length === 1
        ? [...path, "tab"]
        : [...path, "tab", currentTab?.toString()];
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: tabData,
        parent: tabPath,
        component: "tab",
      },
    });
  };

  const handleSelectComonent = (e) => {
    e.stopPropagation();
    const tabs = imxtools?.toArray(data?.tab ?? []);
    const numtabs = tabs ? tabs?.length : null;
    if (data?.tab) {
      handleComponentProp(
        e,
        numtabs > 1 ? currentTab?.toString() : null,
        tabs[currentTab],
        path,
        store,
        "tab"
      );
    } else {
      handleComponentProp(e, null, data, parentPath, store, "detail");
    }
  };

  return (
    <div className="RelationShip-content">
      <div className="ReHeader">
        <div className="goBack" onClick={() => setOpenRelationship(false)}>
          <Icon icon="chevron-left" size={20} className="chevronIcon" />
        </div>
        <div
          className="tabsList"
          style={{
            alignItems: !data?.tab && "center",
          }}
        >
          <DropTargetBody
            accept={["tab"]}
            dragParent={path}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {imxtools?.toArray(data?.tab ? data?.tab : []).length > 0 ? (
              <>
                {imxtools
                  ?.toArray(data?.tab ? data?.tab : [])
                  ?.map((tab, index) => {
                    const numTabs = imxtools?.toArray(
                      data?.tab ? data?.tab : []
                    )?.length;

                    return (
                      <div
                        key={index}
                        className="tabButtom"
                        style={{
                          borderBottom:
                            currentTab === index
                              ? `2px solid ${themes?.[theme]?.primary}`
                              : null,
                          paddingBottom: currentTab === index ? `3%` : null,
                        }}
                        onClick={(e) =>
                          handleTab(e, numTabs >= 1 ? index : null)
                        }
                      >
                        {tab?.label ? tab?.label : " -- "}
                      </div>
                    );
                  })}
              </>
            ) : (
              <p>{readOnly ? "No Tabs here" : "Drop Tabs here"}</p>
            )}
          </DropTargetBody>
        </div>
      </div>
      <div className="ReContent" onClick={(e) => handleSelectComonent(e)}>
        <Tab
          data={imxtools?.toArray(data?.tab ? data?.tab : [])}
          currentTab={currentTab}
          currentPath={currentPath}
          path={
            imxtools?.toArray(data?.tab ? data?.tab : [])?.length > 1
              ? [...path, "tab", currentTab?.toString()]
              : [...path, "tab"]
          }
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}

export default RelationShip;
