import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import "./index.scss";
import { useSelector, useStore } from "react-redux";
import { setIn } from "lodash-redux-immutability";

function DragItemAttribute({
  index,
  children,
  className = "",
  component,
  dragData = {},
  dataAttributes,
  style,
  setDataAttributes,
  fromComponentLib = false,
  setIsDraging = () => {},
  dragParentPath,
}) {
  let store = useStore();

  const moveItem = (hoveredItem, dragedItem) => {
    const item = dataAttributes[dragedItem];
    setDataAttributes((prevState) => {
      const newItems = prevState.filter((i, idx) => idx !== dragedItem);
      newItems.splice(hoveredItem, 0, item);
      return [...newItems];
    });
    const newItems = dataAttributes.filter((i, idx) => idx !== dragedItem);
    newItems.splice(hoveredItem, 0, item);
    const dataSorted = newItems;
    let newData = setIn(
      skeletonState,
      [...dragParentPath, component],
      dataSorted
    );

    store.dispatch({
      type: "explorer/dataAdded",
      payload: {
        skeleton: newData,
        readOnly: false,
      },
    });
  };
  const ref = useRef(null);
  let readOnly = useSelector((state) => state?.entities?.explorer?.readOnly);

  const [{ isDragging }, drag, preview] = useDrag({
    type: component,
    item: {
      type: component,
      index, // Make sure to pass the index for drag and drop logic
      dragData,
      fromComponentLib,
    },
    canDrag: !readOnly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    onDrag: () => {
      setIsDraging(true);
    },
    end: () => {
      setIsDraging(false);
    },
  });
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );

  const [, drop] = useDrop({
    accept: component,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
      const mousePosition = monitor.getClientOffset();
      const hoverClientY = mousePosition.y - hoveredRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const handleDragStart = (e) => {
    setIsDraging(true);
  };
  drag(drop(ref));

  preview(<div>{children}</div>);

  return (
    <div
      ref={ref}
      className={`${className} ${isDragging ? "dragging" : ""}`}
      onDragStart={handleDragStart}
      draggable
    >
      {children}
    </div>
  );
}

export default DragItemAttribute;
