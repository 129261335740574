import React, { useContext, useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import imxtools from "imxtools";
import Icon from "../../../../../../../components/CustomIcon/Icon";
import { handleComponentProp } from "../../services/handleComponentProperties";
import "./index.scss";
import Modal from "../Modal";
import { useState } from "react";
import { getIn } from "lodash-redux-immutability";
import useToasts from "../../../../../../../components/CustomToast/useToasts";
import Form from "../Form";
import DropTargetBody from "../DropTargetBody";
import { AppContext } from "../../../../../services/AppContext";
import { arraysAreEqual } from "../../../../../../../utils/util";
import { isSelectedComponent } from "../../services/isSelectedComponent";
import { useDeviceTheme } from "../../../../../../../DeviceTheme/DeviceThemeContext";

function Fab({
  data,
  path = [],
  readOnly = true,
  forms = [],
  tab = false,
  currentPath = [],
  tabEnabled = false,
}) {
  const [openForm, setOpenForm] = useState(false);
  const [targetForm, setTargetForm] = useState("");
  const [targetFormIndex, setTargetFormIndex] = useState(null);
  const [formHform, setFormHform] = useState(null);
  const { theme, themes } = useDeviceTheme();

  let store = useStore();
  let skeletonState = useSelector((state) =>
    state?.entities?.explorer?.skeleton
      ? state?.entities?.explorer.skeleton
      : {}
  );
  let hformsData = imxtools.toArray(
    getIn(skeletonState, [...path, "hform"]) || []
  );

  let formsData = imxtools.toArray(
    getIn(skeletonState, [...path, "form"]) || []
  );

  const { targetRefEvent, setTargetRefEvent } = useContext(AppContext);
  const { addToast } = useToasts();
  const handleForm = (e, indexEvent, event) => {
    if (indexEvent === null) {
      if (event && event.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    } else {
      if (event && event?.hasOwnProperty("targetref")) {
        setTargetRefEvent(event?.targetref);
        setOpenForm(true);
      } else {
        addToast("you should add targetref to the event !", "warn");
      }
    }
  };

  const handleComp = (e, newPath, component) => {
    e.stopPropagation();
    store.dispatch({
      type: "editor/dataAdded",
      payload: {
        data: data,
        parent: newPath,
        component: component,
      },
    });
  };

  let edit = useSelector((state) => state?.entities?.editor);
  useEffect(() => {
    let foundIndex = -1;
    let foundIndexHform = -1;
    const foundForm = formsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndex = index;
        return true;
      }
      return false;
    });
    const foundHform = hformsData.find((form, index) => {
      if (form?.reference === targetRefEvent) {
        foundIndexHform = index;
        return true;
      }
      return false;
    });

    if (foundForm) {
      setTargetForm(foundForm);
      setTargetFormIndex(foundIndex);
      setFormHform("form");
    } else if (foundHform) {
      setTargetForm(foundHform);
      setTargetFormIndex(foundIndexHform);
      setFormHform("hform");
    } else {
      setTargetForm(null);
      setTargetFormIndex(-1);
    }
  }, [data, hformsData, formsData, targetRefEvent, targetForm, data?.media]);

  return (
    <React.Fragment>
      <div
        className={`fab-container ${
          arraysAreEqual([...path, "fab"], edit?.parentToEdit)
            ? !readOnly && "selectedComponent"
            : !readOnly && "unSelectedComponent"
        }`}
        onClick={(e) => handleComp(e, [...path, "fab"], "fab")}
      >
        <DropTargetBody
          className="drop-fab-zone"
          dropPath={[...path, "fab"]}
          accept={"event"}
          style={{ height: "100%", width: "100%" }}
        >
          {tab === true
            ? imxtools?.toArray(data)?.length > 0 &&
              imxtools?.toArray(data)?.map((fab, index) => {
                return (
                  <div
                    key={index}
                    className="fab-button"
                    onClick={(e) => handleComp(e, [...path, "fab"], "fab")}
                    style={{
                      backgroundColor: tab && "white",
                      boxShadow: tab && "0 0 8px rgba(72, 72, 72, 0.3)",
                      padding: 3,
                      height: "100%",
                      width: "100%",
                    }}
                  ></div>
                );
              })
            : Object?.keys(data)?.length > 0 && (
                <div
                  className={`fab-button ${!readOnly && "hovered"}`}
                  style={{
                    backgroundColor:
                      data?.event?.service?.toLowerCase() == "new" &&
                      !tabEnabled
                        ? "var(--primary)"
                        : "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: data?.event && "0 0 8px #2222224d",
                  }}
                  onClick={(e) =>
                    handleComponentProp(e, null, data, path, store, "fab")
                  }
                >
                  {data?.event ? (
                    imxtools
                      ?.toArray(data?.event ? data?.event : [])
                      ?.map((event, indexEvent) => {
                        const numEvent = imxtools?.toArray(data?.event)?.length;
                        return (
                          <div
                            key={indexEvent}
                            className={`event-content ${
                              isSelectedComponent(
                                readOnly,
                                null,
                                currentPath,
                                "event",
                                numEvent > 1 ? indexEvent?.toString() : null
                              ) && "selectedComponent"
                            }`}
                            style={{
                              backgroundColor:
                                tabEnabled ||
                                data?.event?.service?.toLowerCase() != "new"
                                  ? "white"
                                  : data?.event?.service?.toLowerCase() == "new"
                                  ? "var(--primary)"
                                  : null,
                            }}
                            onClick={(e) =>
                              handleComponentProp(
                                e,
                                numEvent > 1 ? indexEvent?.toString() : null,
                                event,
                                [...path, "fab"],
                                store,
                                "event"
                              )
                            }
                            onDoubleClick={(e) =>
                              handleForm(
                                e,
                                numEvent > 1 ? indexEvent?.toString() : null,
                                event
                              )
                            }
                          >
                            <div
                              className="event-container"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                icon={
                                  data?.event?.service?.toLowerCase() == "new"
                                    ? "new"
                                    : "notoper"
                                }
                                size={22}
                                color={
                                  tabEnabled ||
                                  data?.event?.service?.toLowerCase() != "new"
                                    ? "var(--iconEvent)"
                                    : "#fff"
                                }
                              />
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <div className="empty-fab">Drop Event</div>
                  )}
                </div>
              )}
        </DropTargetBody>
      </div>
      <Modal openModal={openForm} tab={true} app={true}>
        <Form
          data={targetForm}
          path={
            formHform === "form"
              ? formsData?.length === 1
                ? [...path, formHform]
                : [...path, formHform, targetFormIndex]
              : hformsData?.length === 1
              ? [...path, formHform]
              : [...path, formHform, targetFormIndex]
          }
          index={forms?.length === 1 ? null : targetFormIndex?.toString()}
          parentPath={path}
          component={formHform}
          setOpenForm={setOpenForm}
          readOnly={readOnly}
          inGis={false}
        />
      </Modal>
    </React.Fragment>
  );
}

export default Fab;
