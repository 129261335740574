import { useState } from "react";
import LeftSideInspection from "../LeftSideInspection";
import RightSideInspection from "../RightSideInspection";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { handleComponentProp } from "../../services/handleComponentProperties";
import { useStore } from "react-redux";
import Modal from "../Modal";
import DeleteMedia from "../DeleteMedia";

const listQuest = [
  {
    num: 1,
    title: "Initiative Steel haptic",
    completed: true,
    content: [{ qte: "1.01" }, { qte: "1.02" }],
  },
  {
    num: 2,
    title: "Copy",
    completed: false,
    content: [{ qte: "2.01" }, { qte: "2.02" }],
  },
  {
    num: 3,
    title: "British",
    completed: false,
    content: [{ qte: "3.01" }, { qte: "3.02" }],
  },
  {
    num: 4,
    title: "Jewelery",
    completed: false,
    content: [{ qte: "4.01" }, { qte: "4.02" }],
  },
  {
    num: 5,
    title: "Supply-chains synthesizing Legacy",
    completed: false,
    content: [{ qte: "5.01" }, { qte: "5.02" }],
  },
  {
    num: 6,
    title: "Overriding",
    completed: true,
    content: [{ qte: "6.01" }, { qte: "6.02" }],
  },
  {
    num: 7,
    title: "Virginia",
    completed: false,
    content: [{ qte: "6.01" }, { qte: "6.02" }],
  },
  {
    num: 8,
    title: "Corporate Sausages Locks",
    completed: false,
    content: [{ qte: "7.01" }, { qte: "7.02" }],
  },
  {
    num: 9,
    title: "Shoals red Auto",
    completed: false,
    content: [{ qte: "8.01" }, { qte: "8.02" }],
  },
  {
    num: 10,
    title: "Withdrawal web-readiness",
    completed: false,
    content: [{ qte: "9.01" }, { qte: "9.02" }],
  },
  { num: 11, title: "Toys", completed: false, content: { qte: "1.01" } },
  {
    num: 12,
    title: "interfaces Flat",
    completed: false,
    content: { qte: "1.01" },
  },
];

const InspectionModal = ({ closeModal = () => {}, data = {}, path = [] }) => {
  const store = useStore();

  const [viewContent, setViewContent] = useState("home");
  const [questionToDisplay, setQuestionToDisplay] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  const { t } = useTranslation();
  const openSplit = () => {
    setIsOpen((previus) => !isOpen);
  };
  const closeSplit = () => {
    setIsOpen((previus) => !isOpen);
  };
  const handleConfirm = () => {
    if (uploadedPhotos?.length > 0) {
      const newElements = uploadedPhotos?.shift();
      setUploadedPhotos(newElements);
    } else {
      setUploadedPhotos([]);
    }
    setOpenForm(false);
  };

  return (
    <div className="inspection-modal-container">
      <div className="close-modal" onClick={() => closeModal()}></div>
      <div className="modal-content">
        <div
          className="modal-inspection-header"
          onClick={(e) =>
            handleComponentProp(e, null, data, path, store, "inspection")
          }
        >
          {isOpen ? (
            <i
              className="bx bx-horizontal-left"
              style={{
                fontSize: 20,
                fontWeight: "700",
                marginLeft: 4,
                marginTop: 4,
              }}
              onClick={() => openSplit()}
            ></i>
          ) : (
            <i
              className="bx bx-horizontal-right"
              style={{
                fontSize: 20,
                fontWeight: "700",
                marginLeft: 4,
                marginTop: 4,
              }}
              onClick={() => closeSplit()}
            ></i>
          )}
          <div className="title-txt">
            {t("Daily Maintenance and inspection...")}
          </div>
          <div
            style={{
              height: 20,
              width: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 500,
              backgroundColor: "#6A549E",
              marginRight: 4,
              marginTop: 4,
            }}
            onClick={() => closeModal()}
          >
            <i className="bx bx-x" style={{ fontSize: 16, color: "#fff" }}></i>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            height: "92%",
          }}
        >
          <LeftSideInspection
            isOpen={isOpen}
            viewContent={viewContent}
            setViewContent={setViewContent}
            listQuestions={listQuest}
            setQuestionToDisplay={setQuestionToDisplay}
            data={data}
            path={path}
          />
          <RightSideInspection
            isOpen={isOpen}
            viewContent={viewContent}
            setViewContent={setViewContent}
            listQuestions={listQuest}
            questionToDisplay={questionToDisplay}
            setQuestionToDisplay={setQuestionToDisplay}
            data={data}
            path={path}
            setOpenForm={setOpenForm}
            uploadedPhotos={uploadedPhotos}
            setUploadedPhotos={setUploadedPhotos}
          />
        </div>
      </div>
      <Modal openModal={openForm}>
        <DeleteMedia
          setOpenForm={setOpenForm}
          handleConfirm={handleConfirm}
          uploadedPhotos={uploadedPhotos}
          setUploadedPhotos={setUploadedPhotos}
        />
      </Modal>
    </div>
  );
};
export default InspectionModal;
