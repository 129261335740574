import fetchSysInfo from "../services/fetchSysInfo";

const loadSysInfo = async (setData = () => {}) => {
  try {
    const fetchedData = await fetchSysInfo();
    setData(fetchedData.content);
  } catch (err) {
    //console.log(err.message);
    return;
  }
};

export default loadSysInfo;
